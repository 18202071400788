{
  "100": "Algemene voorwaarden",
  "101": "Privacy-beleid",
  "102": "Versleutelde communicatie",
  "107": "Gebruik je net als ik",
  "108": "ProtonMail",
  "109": "voor je e-mail? Dan hoef je niets bijzonder te doen. E-mail tussen tussen gebruikers van ProtonMail wordt automatisch versleuteld.",
  "110": "Gebruik je een andere e-mail dienstverlener dan kan je gebruik maken van mijn publieke sleutel om een bericht te versleutelen. De 16 karakters die je zag, onderaan op de homepagina, is een stukje van de ",
  "111": "digitale vingerafdruk",
  "112": " van mijn publieke sleutel. De volledige publieke sleutel kan je hieronder downloaden. Je kan ook mijn volledige digitale vingerafdruk kopiëren via het klembord.",
  "113": "Niet vertrouwd met PGP? Wil je meer leren over het versleutelen van berichten of bestanden?",
  "116": "Of boek een afspraak met mij en ik help je op weg.",
  "117": "Download Publieke Sleutel",
  "118": "PGP Vingerafdruk Kopiëren",
  "119": "TOR ondersteuning",
  "120": "Deze website is ook beschikbaar via het TOR netwerk (The Onion Router). Verbindingen via TOR maken het zeer lastig om jouw locatie en identiteit te achterhalen. Je wint aan privacy, maar het laden van de site kan vertraging oplopen.",
  "121": "Om te verbinden via Tor gebruik je een speciale browser,",
  "122": "Tor Browser",
  "123": ", of een browser met ingebouwde ondersteuning voor Onion adressen zoals",
  "124": "Brave Browser",
  "126": "Deze site is ook bereikbaar via het Tor Netwerk:",
  "127": "Lion Digits logo",
  "128": "Ondernemingsnummer",
  "129": "Email Joris Raymaekers",
  "130": "Email icoon",
  "131": "Bel mij vanuit België",
  "132": "Telefoon België icoon",
  "133": "Bel mij vanuit Spanje",
  "134": "Telefoon Spanje icoon",
  "135": "Contacteer mij via de Signal Messenger App",
  "136": "Signal Messenger App logo",
  "137": "Contacteer mij via Skype",
  "138": "Skype logo",
  "139": "Bekijk mijn profiel op Linked-In",
  "140": "Linked-In logo",
  "141": "Bekijk mijn profiel op X",
  "142": "X logo",
  "143": "Bekijk de code die ik schrijf op Github",
  "144": "Github logo",
  "145": "Bekijk mijn visuele creaties op Codepen",
  "146": "Codepen logo",
  "147": "Kopiëren naar klembord",
  "148": "Kopiëren naar klembord icoon",
  "149": "Wat is dit?",
  "150": "Vraagteken icoon",
  "152": "Signal Messenger App logo",
  "153": "externe link icoon",
  "154": "PGP introductie artikel",
  "155": "externe link icoon",
  "156": "Download icoon",
  "157": "Kopiëren naar klembord icoon",
  "158": "externe link icoon",
  "159": "externe link icoon",
  "160": "Maak een afspraak",
  "162": "Vertalen icoon",
  "163": "Algemene voorwaarden",
  "164": "Laatst aangepast op 01-03-2022",
  "165": "Download hier de algemene voorwaarden van Lion Digits:",
  "166": "Download Algemene Voorwaarden",
  "167": "Op deze pagina leest u over de algemene voorwaarden van Lion Digits. De beschrijvingen op deze pagina zijn indicatief. Download de volledige algemene voorwaarden voor een compleet overzicht.",
  "168": "Bedrijfsgegevens",
  "169": "Lion Digits: ICT consulent en ontwikkelaar van websites, webapplicaties en software, en grafisch ontwerp met maatschappelijke zetel te:",
  "170": "en ondernemingsnummer:",
  "171": "Offertes",
  "172": "Alle offertes en prijsopgaven door Lion Digits zijn geheel vrijblijvend t.a.v. de opdrachtgever.",
  "173": "Adviezen, voorstellen en offertes gedaan door Lion Digits zijn persoonlijk gericht aan de opdrachtgever. Het is niet toegestaan om zonder toestemming van Lion Digits, deze ter inzage te verstrekken aan andere partijen of om deze te gebruiken anders dan ter beoordeling van de aanbieding.",
  "174": "Lion Digits is slechts aan de offertes gebonden indien de aanvaarding ervan door de opdrachtgever schriftelijk binnen 14 dagen wordt bevestigd.",
  "175": "Een overeenkomst komt tot stand van zodra de offerte door de opdrachtgever ondertekend ontvangen wordt door Lion Digits, en zodra de vooruitbetaling van 25% op het totaalbedrag ontvangen is.",
  "176": "Enkel als er op de offerte vermeld staat dat er geen vooruitbetaling verwacht wordt, komt de overeenkomst tot stand van zodra de offerte door de opdrachtgever ondertekend ontvangen wordt.",
  "177": "Garantie",
  "178": "Na het opleveren van een project start een garantieperiode van 30 kalenderdagen. Deze garantieperiode laat toe om fouten of bugs op te lossen binnen de offerte.",
  "179": "Het recht op garantie vervalt als de klant toegang wenst tot een account die structurele wijzigingen kan doorvoeren aan de website of aan de code, zoals een superuser account van een website, toegang tot het core bestandssysteem door middel van (S)FTP of toegang met schrijfrechten tot de gebruikte databasesystemen.",
  "180": "Binnen het kader van een onderhoudscontract kunnen bijkomende garanties worden bepaald.",
  "181": "Aansprakelijkheden",
  "182": "In geval van toerekenbare tekortkoming in de nakoming van de overeenkomst is Lion Digits slechts aansprakelijk voor vervangende schadevergoeding tot het factuurbedrag. Iedere aansprakelijkheid van Lion Digits voor enige andere vorm van schade is uitgesloten, daaronder begrepen vergoeding van indirecte schade, gevolgschade of schade wegens gederfde omzet of winst.",
  "183": "Prijzen",
  "184": "Alle opgegeven prijzen zijn exclusief BTW, tenzij nadrukkelijk anders vermeld staat.",
  "185": "Extra wensen van de opdrachtgever die niet van tevoren zijn afgesproken zullen resulteren in meerwerk waarvoor Lion Digits naar evenredigheid zal moeten worden vergoed.",
  "186": "Alle prijzen worden worden jaarlijks geïndexeerd, tenzij door onderlinge overeenstemming tussen de partijen schriftelijk anders is bepaald.",
  "187": "Betaling",
  "188": "Na afronding van de opdracht zal Lion Digits een factuur sturen voor het met de overeenkomst gemoeide bedrag, verminderd met een eventueel eerder voldane vooruitbetaling. De opdrachtgever dient binnen 14 dagen na de factuurdatum het verschuldigde bedrag te voldoen.",
  "189": "Indien de opdrachtgever de betalingstermijn heeft overschreden, wordt door Lion Digits een aanmaning tot betaling verstuurd. De kosten van een aanmaning, t.w.v. € 30,00 excl btw, worden in rekening gebracht aan de opdrachtgever.",
  "190": "Wanneer de opdrachtgever binnen 14 dagen na het verzenden van de eerste aanmaning nog niet aan zijn verplichtingen heeft voldaan, dan wordt de inning uitbesteed. Alle kosten hieruit voortvloeiend zijn voor rekening van de opdrachtgever. Indien het gaat om een website of andere online diensten dan worden deze eveneens na dezelfde overschrijdingstermijn van 14 dagen offline gehaald. Kosten voor het nadien eventueel terug online brengen van de website zijn volledig voor de opdrachtgever.",
  "191": "Privacy",
  "192": "Lion Digits verzamelt en verwerkt de persoons- en bedrijfsgegevens die het van de opdrachtgever ontvangt met als doel de uitvoering van de overeenkomst, het klantenbeheer, de aankopen, de boekhouding en direct marketingactiviteiten. De rechtsgronden zijn de uitvoering van een overeenkomst, het vervullen van wettelijke en reglementaire verplichtingen en/of het gerechtvaardigd belang.",
  "194": "← Home",
  "195": "Download icoon",
  "196": "Lees het privacy-beleid",
  "199": "Hela, opgepast!",
  "200": "Hier hoor je niet te zijn...",
  "201": "terug",
  "207": "maak een afspraak",
  "211": "Slimme ondernemers",
  "212": "beseffen het belang van een goed ontworpen aanwezigheid op het web, hoe die presentatie ervoor zorgt dat hun doelgroep wordt bereikt en dat de daaruit volgende leads worden omgezet in",
  "213": "extra inkomsten",
  "219": "Meer weten over wie ik ben? Op zoek naar iemand met technische competenties in JavaScript, TypeScript, NodeJs of ReactJs? Benieuwd naar het brein achter de technologie? Lees meer over mijn verhaal en ontdek de kracht van mijn technische vaardigheden.",
  "220": "Lees meer...",
  "223": "Bekijk website",
  "224": "technische fiche",
  "225": "Voor het biermerk Goudster uit Halen werd een nieuwe website gebouwd met een handig overzicht van de lange geschiedenis van de brouwerij en een module voor online bestellingen.",
  "226": "Bekijk website",
  "227": "Het klantenbestand van Brasserie Lemmensmolen in Beverlo kreeg een boost na het lanceren van een elegante website met een module voor online reserveren.",
  "228": "Bekijk website",
  "230": "Bekijk website",
  "231": "technische fiche",
  "232": "Interesse? Gesprekje starten!",
  "233": "Laat ons vrijblijvend eens babbelen om te verkennen hoe we het beste kunnen samenwerken. Plan hier online meteen een afspraak in.",
  "234": "maak een afspraak",
  "235": "Driehoek icoon",
  "236": "Piramide icoon",
  "237": "Zandloper icoon",
  "238": "Foto van Joris Raymaekers",
  "239": "JavaScript logo",
  "240": "JavaScript",
  "243": "TypeScript logo",
  "244": "TypeScript",
  "248": "Demo video van de Catacamp website",
  "249": "Bezoek de Catacamp website",
  "250": "Catacamp logo",
  "251": "externe link icoon",
  "252": "Bekijk de code op GitHub",
  "253": "Github logo",
  "255": "Demo video van de Goudster website",
  "256": "Bezoek de website van Goudster",
  "257": "Goudster logo",
  "258": "externe link icoon",
  "259": "Homepagina Brasserie Lemmensmolen",
  "260": "Homepagina Brasserie Lemmensmolen",
  "261": "Bezoek de website van Brasserie Lemmensmolen",
  "262": "Lemmensmolen logo",
  "263": "externe link icoon",
  "265": "Demo video van de Travel Agency website",
  "266": "Bezoek de website van Clear View Escape",
  "267": "Clear View Escape logo",
  "268": "externe link icoon",
  "269": "Bekijk de code op Github",
  "270": "Github logo",
  "272": "Lion Digits, web ontwikkeling",
  "273": "Privacy-beleid",
  "274": "Laatst aangepast op 01-03-2022",
  "275": "| Algemene bepalingen",
  "276": "Op deze pagina leest u welke gegevens worden verzameld als u deze website gebruikt of communiceert met Lion Digits, waarom deze gegevens worden verzameld en hoe hiermee uw gebruikservaring wordt verbeterd.",
  "277": "Dit privacybeleid is van toepassing op de diensten van Lion Digits. Lion Digits kan niet verantwoordelijk worden gesteld voor het privacybeleid van andere sites en bronnen waarmee u in contact komt via deze website.",
  "278": "Door gebruik te maken van deze website of door te communiceren met Lion Digits geeft u aan dit privacybeleid te accepteren.",
  "279": "Communicatie",
  "280": "Wanneer u e-mail of andere berichten naar ons verzendt, is het mogelijk dat we die berichten bewaren. Merk op dat sommige (klant)gegevens verplicht moeten worden bewaard in verband met administratieve, wettelijke of beveiligingsdoeleinden. Soms wordt u gevraagd naar bijkomende persoonlijke gegevens die voor de desbetreffende situatie relevant zijn. Dit maakt het mogelijk uw vragen te verwerken en uw verzoeken te beantwoorden. Deze gegevens worden opgeslagen op eigen beveiligde servers van Lion Digits of die van een derde partij.",
  "281": "Cookies",
  "282": "Functionele cookies",
  "283": "Deze website maakt gebruik van “cookies”, kleine tekstbestanden die op uw computer worden geplaatst om de website beter en sneller te helpen functioneren. Bijvoorbeeld, om de taalkeuze te onthouden.",
  "284": "De meeste browsers zijn standaard ingesteld om cookies te accepteren, maar u kunt uw browser opnieuw instellen om alle cookies te weigeren of om aan te geven wanneer een cookie wordt verzonden. Het is echter mogelijk dat sommige functies en diensten, op deze en andere websites, niet correct functioneren als cookies zijn uitgeschakeld in uw browser.",
  "285": "Analytische cookies",
  "286": "Cookies kunnen ook worden gebruikt om te analyseren hoe gebruikers de site gebruiken. De door het cookie gegenereerde informatie over uw gebruik van de website kan worden overgebracht naar een eigen beveiligde server van Lion Digits of die van een derde partij. Deze informatie wordt gebruikt om bij te houden hoe u de website gebruikt en om rapporten over de website-activiteit op te stellen.",
  "287": "Deze informatie wordt enkel gebruikt voor de doeleinden die worden beschreven in dit privacybeleid.",
  "288": "Ingesloten inhoud van andere sites",
  "289": "Informatie op deze site kan ingesloten inhoud bevatten (bijvoorbeeld video’s, afbeeldingen, berichten, enz.). Ingesloten inhoud van andere sites gedraagt zich exact hetzelfde alsof de bezoeker deze andere site heeft bezocht. Deze sites kunnen gegevens over u verzamelen, cookies gebruiken, extra tracking van derde partijen insluiten en uw interactie met deze ingesloten inhoud monitoren, inclusief het vastleggen van de interactie met ingesloten inhoud op deze site.",
  "290": "Inzage persoonlijke informatie en contactgegevens",
  "291": "Alle bezoekers hebben de mogelijkheid tot het inzien, veranderen, of verwijderen van alle persoonlijke informatie die aan Lion Digits is verstrekt.",
  "292": "e-mail",
  "293": "← Home",
  "296": "Over mijzelf...",
  "297": "Joris Raymaekers",
  "301": "Boeken, rozen en vrijheid",
  "302": "Een dagje door de straten van Barcelona om de temperatuur op te meten van het Catalaanse politieke proces.",
  "303": "Lees op Doorbaak.be",
  "305": "Technologie ontwikkelen en inzetten als hefboom om je persoonlijke of zakelijke impact te vergroten, daar help ik graag aan mee.",
  "312": "Van ontwerp naar code",
  "313": "front-end ontwikkeling",
  "314": "Github-pagina",
  "316": "voorbeeld",
  "317": "voorbeeld",
  "318": "voorbeeld",
  "321": "Layouts en visuele animaties kunnen in alle mogelijke kleuren en configuraties. Een selectie voorbeelden en experimenten vind je op mijn Codepen:",
  "322": "Codepen front-end mini-projects",
  "323": "Collecties omvatten onder meer: formulieren met validatie aan de client-zijde, afbeeldingsgalerijen met DOM-elementfiltering, modals en css-animaties...",
  "324": "Codepen Profiel",
  "333": "Serverbeheer en clouddiensten",
  "334": "magie achter de schermen",
  "337": "voorbeeld",
  "338": "voorbeeld",
  "339": "voorbeeld",
  "343": "ontwikkelingsomgeving",
  "344": "Voor het ontwikkelen van applicaties wordt een beroep gedaan op een ontwikkelingsomgeving of IDE, Integrated Development Environment. Een collectie programma's en instrumenten om je code te schrijven en te testen.",
  "351": "Fun Zone",
  "352": "Ok, genoeg gelezen. Hieronder vind je een aantal mini-projectjes en spelletjes waar je een tijdje zoet mee bent. Veel plezier!",
  "353": "Guess the Flag",
  "354": "Speel nu",
  "355": "Task Manager",
  "356": "Naar de app",
  "357": "Stenographer",
  "358": "Naar de app",
  "359": "Patatap",
  "360": "Speel nu",
  "361": "De speler krijgt een willekeurige landvlag voorgeschoteld en vier mogelijke antwoorden. Probeer de juiste te kiezen. Game statusbeheer gebouwd met ReactJs en gegevens opgehaald uit de REST-Countries-API.",
  "362": "Single page app voor taakbeheer met aangepaste JSON API. Voornaamste UI-functie: selecteer meerdere items (shift-toets), verwijder en update een aangepaste selectie van taken. Applicatie gebouwd met NodeJs, Express & MongoDb. API-eindpunten voor GET / POST / PUT / DELETE-verzoeken. AJAX-verzoeken met jQuery.",
  "364": "Tap, klik of typ willekeurig op je toetsenbord. Elke toetsaanslag produceert een vuurwerk van willekeurig gegenereerde geluiden en kleuren. Applicatie gebouwd met de bibliotheken PaperJs en HowlerJs.",
  "368": "Foto van Joris Raymaekers",
  "369": "Bezoek het MS-DOS Games Archive",
  "370": "externe link icoon",
  "371": "Casa Pedrera Barcelona",
  "372": "Doorbraak logo",
  "373": "JavaScript logo",
  "374": "JavaScript",
  "377": "TypeScript logo",
  "378": "TypeScript",
  "381": "Naar de Github-pagina",
  "382": "externe link icoon",
  "383": "Bekijk een voorbeeld op Github",
  "384": "externe link icoon",
  "385": "Bekijk een voorbeeld op Github",
  "386": "externe link icoon",
  "387": "Bekijk een voorbeeld op Github",
  "388": "externe link icoon",
  "389": "Bekijk een voorbeeld op Github",
  "390": "externe link icoon",
  "392": "Codepen profiel snapshot",
  "393": "Codepen logo",
  "394": "Bekijk code snippet op mijn Github profiel",
  "395": "externe link icoon",
  "396": "Bekijk code snippet op mijn Github profiel",
  "397": "externe link icoon",
  "398": "Bekijk code snippet op mijn Github profiel",
  "399": "externe link icoon",
  "401": "Raad het land van de vlag",
  "402": "Technische details...",
  "403": "Vraagteken icoon",
  "405": "Afbeelding van de Task Manager app",
  "406": "Technische details...",
  "407": "Vraagteken icoon",
  "409": "Afbeelding van de Stenographer app",
  "410": "Technische details...",
  "411": "Vraagteken icoon",
  "413": "Afbeelding van de Patatap app",
  "414": "Technische details...",
  "415": "Info icoon",
  "418": "Lees meer over het privacybeleid,",
  "419": "Vragen of verzoeken in verband met de verwerking van uw gegevens kan u versturen via",
  "420": "of stuur een brief naar onderstaand adres.",
  "422": "Lion Digits | Krachtige digitale tech. Door Joris Raymaekers.",
  "423": "hier",
  "424": "Advies op maat voor jouw aanwezigheid op het web",
  "426": "Website ontwikkeling, onderhoud en optimalisatie",
  "427": "Crossplatform web apps met ReactJs en React Native",
  "428": "Server app ontwikkeling met NodeJs",
  "430": "Nginx configuratie:",
  "431": "Docker Compose configuratie:",
  "432": "Bash script voor automatische code updates naar VPS server:",
  "435": "Taiga",
  "436": "externe link icoon",
  "437": "tot ook dat weer verandert.",
  "440": "externe link icoon",
  "441": "Taiga.io, open source agile project management software",
  "443": "Versie 1.0,",
  "444": "Versie 1.0,",
  "445": "(1983, Herk-de-Stad)",
  "447": "afbeelding van digitale diensten",
  "453": "Website Text Translator (WTT)",
  "454": "Bekijk de NPM module op NPMjs.com",
  "455": "WTT",
  "459": "Bekijk de code op Github",
  "461": "Demo website van CataCamp",
  "463": "Lees het volledige artikel op de website van Doorbraak...",
  "464": "Mail, Text, Telefoon, etc.",
  "465": "andere opties...",
  "466": "IT diensten & Over mijzelf",
  "467": "Digitaal maatwerk",
  "468": " Website of web app nodig? Op zoek naar een freelance IT-man? Hier moet je zijn! ",
  "474": "Demo video",
  "475": "Demo video",
  "476": "Demo video",
  "477": "Signal",
  "481": "ProtonMail",
  "483": "PGP: Pretty Good Privacy",
  "484": " Deze website gebruikt cookies om een optimale ervaring aan te bieden. ",
  "485": "Meer info...",
  "486": "Begrepen",
  "487": "Business icoon",
  "488": "Sleutel icoon",
  "489": "Tor Netwerk icoon",
  "490": "Onion-service URL",
  "491": "Mattheus 6",
  "492": " 9 Gij dan bidt aldus: Onze Vader, Die in de hemelen zijt! Uw Naam worde geheiligd. 10 Uw Koninkrijk kome. Uw wil geschiede, gelijk in den hemel alzo ook op de aarde. 11 Geef ons heden ons dagelijks brood. 12 En vergeef ons onze schulden, gelijk ook wij vergeven onzen schuldenaren. 13 En leid ons niet in verzoeking, maar verlos ons van den boze. Want Uw is het Koninkrijk, en de kracht, en de heerlijkheid, in der eeuwigheid, amen. ",
  "496": "Ontdek wat ik weet over bitcoin...",
  "499": " Maar de digitale wereld is niet alles en lang geleden zei men al: ",
  "500": ". Dus in mijn vrije tijd vind je mij regelmatig wandelend in de natuur met de hond en krachttraining houdt me fit en sterk. Ik trap ook nog wel eens graag tegen een bal. ",
  "501": "mens sana in corpore sano",
  "502": " In een vorig hoofdstuk was ik actief als logistiek medewerker; de liefde bracht me vervolgens naar Catalonië; en een Master in de Vergelijkende Politiek leidde tot een episode in de journalistiek. Voor zij die graag lezen en net als ik Catalonië een warm hart toedragen, vind je hieronder nog een selectie van eigen publicaties uit de oude doos: ",
  "503": "Casa de les Punxes, Barcelona",
  "504": " Het Huiswerk van de Catalanen (Deel I) ",
  "505": " Joris Raymaekers, een van onze Doorbraak-medewerkers in Barcelona, meet de politiek temperatuur op in Catalonië. ",
  "507": "Parlament de Catalunya, hemicicle",
  "508": " Het Huiswerk van de Catalanen (Deel II) ",
  "509": " Het voortschrijdende Catalaanse proces van nationale transitie transformeert geleidelijk aan het Catalaanse politieke landschap. ",
  "512": "Lees meer...",
  "513": " Web applicatie voor het oplijsten van kampeerplaatsen in Catalonië. Dit project beslaat de ganse 'full web stack': een backend API met database en een front-end web app. Klik door naar de technische fiche voor meer details. ",
  "514": " Showcase website voor reisbureau Clear View Escape. Klik door naar de technische fiche voor meer details. ",
  "515": "Bekijk mijn profiel op Nostr",
  "516": "Nostr logo",
  "517": "Bekijk mijn profiel op Instagram",
  "518": "Instagram logo",
  "519": " De meest gebruiksvriendelijke manier om versleutelde berichten te verzenden is via de Signal Messenger app: ",
  "520": "Signal",
  "521": " Deze gratis en open-source berichten-app biedt end-to-end encryptie om berichten, foto's, video's en oproepen te verzenden. Signal vraagt alleen je telefoonnummer wanneer je jou voor het eerst bij de service registreert. Er worden geen metadata rondom communicatie bewaard. Met de app kunnen berichten ook automatisch worden gewist zodra ze zijn gelezen. ",
  "522": "Voeg mij toe als contactpersoon in Signal via deze link:",
  "523": "Signal Contact",
  "524": "Stuur mij een",
  "525": "e-mail",
  "526": "Lees hier een",
  "527": "introductie",
  "528": "Bitcoin accepted here logo",
  "529": "Bitcoin accepted here logo",
  "530": "Bitcoin logo",
  "531": "Bitcoin logo",
  "532": "Betalen in bitcoin? Dat kan!",
  "533": " Bitcoin is een interessant experiment en een andere manier om met geld om te gaan. Ik vind het zowel vanuit technisch als monetair perspectief erg boeiend om volgen en ik ben dan ook bereid om het te accepteren als betaalmiddel. ",
  "534": " Bitcoin is toegankelijk voor iedereen maar allicht geen spek voor ieders bek. ",
  "535": ". ",
  "536": "Ik ben geen promotor van bitcoin noch financieel adviseur en ik zal er nooit zelf spontaan iemand over contacteren of over aanspreken",
  "537": " Er circuleert heel wat verwarrende en onjuiste informatie over bitcoin en wat men 'crypto' noemt. Informeer je grondig en als je op zoek bent naar een aanknopingspunt dan maak ik nederig twee gratis suggesties: ",
  "538": "Bitcoin for Everybody —",
  "539": "[Saylor Academy]",
  "540": " Bitcoin for Everybody is een Engelstalige cursus (12u) met een goed overzicht van de ontstaansgeschiedenis, basisideeën en concepten rondom bitcoin. Na het afronden van de cursus bekom je zoals ik een certificaat als dit: Saylor Academy ",
  "541": "certificaat",
  "542": "Bitcoin is... —",
  "543": "[Case Bitcoin]",
  "544": " Je kan bitcoin benaderen vanuit meerdere invalshoeken: digitaal goud, een doorbraak in de computerwetenschap, een munt van en voor het internet, een economisch experiment, een speculatieve investering, etc. CaseBitcoin maakt de case voor bitcoin vanuit verschillende perspectieven en heeft ook een handig overzicht van de meeste voorkomende bedenkingen en ",
  "545": "kritieken",
  "546": " Het document waarmee het allemaal begon, ",
  "547": ", van de anonieme Satoshi Nakamoto vind je ",
  "548": ". ",
  "549": "Bitcoin: A Peer-to-Peer Electronic Cash System",
  "550": "in dit pdf-bestand",
  "551": "React logo",
  "552": "React",
  "553": "Node.js logo",
  "554": "Node.js",
  "555": "Mijn eerste ervaring met computers en computer code kwam er door het fixen van nukkige video-spelletjes in de MS-DOS command line. Nooit gehoord van MS-DOS? Dat ging toen",
  "556": "zo...",
  "557": " Wat ooit begon als hobby met het configureren van spelletjes en het repareren van pc's en telefoons, is nu geëvolueerd tot het bouwen van complete web applicaties.",
  "558": " Maar met computers werken doe ik niet voor mezelf. De meeste voldoening en het grootste plezier haal ik uit het kunnen helpen van anderen met het verlichten van hun IT bekommernissen. ",
  "559": " Naast mijn werk op het web ben ik ook gefascineerd door cryptografie en de mogelijke toepassingen met digitale sleutels voor beveiliging of nieuwe vormen van geld en betalingen zoals ",
  "560": "bitcoin",
  "561": ". Vandaar ook de verwijzingen naar zaken zoals ",
  "562": "Wat ik kan doen voor jou",
  "563": " Gepersonaliseerd advies om ervoor te zorgen dat je online aanwezigheid precies aansluit bij jouw doelen en visie. ",
  "564": " Creëren van dynamische en moderne web apps met behulp van het uitgebreide JavaScript ecosysteem van bibliotheken en het meest recente digitale gereedschap. ",
  "566": " Expertise in het bouwen en beheren van WordPress websites met DIVI en WP-Bakery voor een naadloze en flexibele ervaring. ",
  "567": " Krachtige en gebruiksvriendelijke crossplatform web-applicaties met behulp van de nieuwste technologieën zoals React en React Native. ",
  "568": " Maak gebruik van de snelheid en schaalbaarheid van Node.js voor het bouwen van robuuste serverapplicaties met JavaScript. ",
  "569": " Ben je technisch aangelegd? Scroll dan zeker verder naar een overzicht van mijn technische vaardigheden. Bekijk enkele voorbeelden, volg de Github-links naar broncode of grasduin door de uitgelichte creaties op Codepen... ",
  "570": "Technische vaardigheden",
  "571": " Een overzicht van mijn technische competenties ",
  "572": "Code icoon",
  "573": "Code icoon",
  "574": "Git branch icoon",
  "575": "Git branch icoon",
  "576": "Laptop icoon",
  "577": "Laptop icoon",
  "578": "Bestand icoon",
  "579": "Bestand icoon",
  "580": "Front-end Ontwikkeling",
  "581": "Backend-end Ontwikkeling",
  "582": "Servers & Clouddiensten",
  "583": "Programmeertalen",
  "584": "IDE & Projectbeheer",
  "585": "UI/UX implementatie",
  "586": " Vele websites — waaronder deze eigenste site, worden ontworpen met een ontwerpprogramma zoals AdobeXd of Figma en vervolgens omgezet naar de code van het web in HTML5, (S)CSS en JavaScript. Daarbij wordt bijzondere aandacht geschonken aan een ",
  "587": " benadering en een logische opdeling van de code in secties en componenten met een methodologie zoals Block-Element-Modifier ",
  "588": "mobile-first",
  "589": "Een voorbeeld van hoe dit er in de praktijk precies uitziet vind je in de broncode voor deze website op deze ",
  "590": "Front-end & JavaScript bibliotheken",
  "591": " Het ontwikkelen gaat (soms) sneller als je kan verder bouwen op het werk van anderen. Bibliotheken bevatten reeds voorgeprogrammeerde en geteste componenten, layouts en functies die gemakkelijk herbruikbaar zijn. Bijvoorbeeld, de animaties bij het scrollen op deze website worden mogelijk gemaakt met de AOS-bibliotheek (Animation-On-Scroll). Enkele andere voorbeelden van bibliotheken waar ik mee vertrouwd ben: ",
  "592": "Bundelen en optimaliseren",
  "593": " Al de ontwerpcode, afbeeldingen en scripts beheren die bij een omvangrijke website horen, wordt al snel een hele opgave. Voor het beheer en het optimaliseren van al die bronnen maak ik gebruik van gespecialiseerd gereedschap zoals ParcelJS, Webpack of GulpJS. Bekijk een voorbeeld met GulpJS op ",
  "594": "Github",
  "595": "React",
  "596": " Moderne interactieve gebruikersinterfaces kunnen behoorlijk complex worden en dan heb je vaak behoefte aan een geavanceerde JavaScript bibliotheek zoals React die het bouwen en beheren van UI componenten efficiënt en schaalbaar probeert maakt te maken. ",
  "597": "Bekijk een eenvoudig voorbeeld op",
  "598": "mijn Github",
  "599": "Of bekijk de live versie via deze",
  "600": "Ga naar de app Cooking-react",
  "601": "link",
  "602": " Server-side apps en API's ",
  "603": " ontwikkeling met Node.js ",
  "604": " Node.js een solide open-bron runtime-omgeving voor JavaScript. Het is een heel erg veelzijdige omgeving om applicaties in te bouwen die vervolgens op zowat elk platform kunnen worden uitgevoerd. JavaScript is dé taal van het web en via Node.js kan je dezelfde programmeertaal hanteren voor zowel de gebruikersinterface (UI) als de logica voor de server of backend taken. ",
  "605": " In combinatie met TypeScript — een superset van JavaScript, kan je met Node.js heel solide en performante programma's bouwen. Van webapps en REST API's tot Command Line applicaties en test oplossingen. ",
  "606": "Voorbeelden van Node.js projecten",
  "607": " is een Node.js NPM module met een Command Line Interface. Het programma helpt je om de tekst van bestaande HTML bestanden te vertalen via de Google Translate API en vervolgens te integreren en beheren in je website met behulp van JSON bestanden. De CLI interface is gebouwd met Commander en het ontleden van HTML elementen gebeurt met Node-HTML-Parser. Dit programma, met bijhorende documentatie, is beschikbaar als open-bron software in het NPM register: ",
  "608": ". ",
  "609": " is een project gebouwd in Node.js met een ExpressJS server, een REST API, authenticatie met PassportJS en Redis voor sessie-opslag. Andere data wordt opgeslagen in een MongoDB database met Mongoose voor objectmodellering (ODM). Bekijk de code op ",
  "610": " of een werkende demo-versie via deze ",
  "611": "CataCamp",
  "612": "Github",
  "613": "Catacamp website.",
  "614": "Domein en DNS configuratie",
  "615": " Je web app moet bereikbaar zijn via een server en een domeinnaam. Het configureren van domeinnamen en DNS-instellingen (Domain Name System) met subdomeinen, email en SSL certificaten is een taak die hoort bij elke website. Voor een optimaal efficiënt bereik van de website kan eventueel ook een CDN (Content Delivery Network) worden ingesteld. ",
  "616": "Serverbeheer",
  "617": " Server- en systeembeheer is niet mijn hoofdactiviteit maar ik ben wel bekwaam in het opzetten en onderhouden van virtuele servers (VPS) voor zowel Node.js apps als Wordpress websites. Werken met en configureren van instrumenten zoals Nginx (reverse proxy), PM2 process manager, Tmux SSH sessies of Docker containers behoort tot mijn vaardigheden. ",
  "618": " Bij het werken in de command-line en servers stuit je vaak op dezelfde soort taken die je kan automatiseren met behulp van Bash scripts en cron jobs. Enkele voorbeelden van Bash scripts en configuraties vind je via onderstaande links: ",
  "619": "Cloud-functies en clouddiensten",
  "620": ", ",
  "621": ", ",
  "622": ", of ",
  "623": " Cloud-functies zijn onderdeel van 'serverless architecturen' waarbij het beheer van de serverinfrastructuur uit handen wordt gegeven aan clouddiensten zoals ",
  "624": "Naar AWS Amazon Lambda",
  "625": "Amazon AWS Lambda",
  "626": "Naar Google Cloud Functions",
  "627": "Google Cloud Functions",
  "628": "Naar Microsoft Azure Functions",
  "629": "Microsoft Azure Functions",
  "630": "Naar Netlify",
  "631": "Netlify",
  "632": ". Voordelen zijn schaalbaarheid, kostenefficiëntie en verminderd onderhoud, maar ",
  "633": "The real benefits and drawbacks of Cloud Functions",
  "634": "de nadelen",
  "635": "omvatten vendor lock-in en mogelijke uitdagingen met latency en debugging.",
  "636": "Een voorbeeld van een simpele cloud-functie vind je op mijn",
  "637": "Github",
  "638": "Programmeertalen",
  "639": "Python logo",
  "640": "Python logo",
  "641": "PHP logo",
  "642": "PHP logo",
  "643": "JavaScript",
  "644": " Mijn focus ligt op JavaScript en bij uitbreiding TypeScript — een vaak gebruikte superset van JavaScript in de context van front-end ontwikkeling met React. ",
  "645": " Mijn eerste lijntjes JavaScript code leerde ik schrijven aan de hand van het boek Eloquent JavaScript van Marijn Haverbeke. Niet het meest eenvoudige beginnersboek, wel rigoureus. ",
  "646": "Eloquent JavaScript van Marijn Haverbeke",
  "647": "Eloquent JavaScript",
  "648": " Op mijn eigen Github pagina vind je nog een aantal repositories met notities, oefeningen en programmeeruitdagingen die getuigen van mijn eigen leerproces: ",
  "649": "JavaScript notities",
  "650": "JavaScript notities",
  "651": "Programmeeruitdagingen I",
  "652": "Programmeeruitdagingen I",
  "653": "— incl. Jest Unit Testing",
  "654": "Programmeeruitdagingen II",
  "655": "Programmeeruitdagingen II",
  "656": "— incl. Jest Unit Testing",
  "657": "Python",
  "658": " Python wordt terecht geroemd voor z'n elegantie en eenvoud en het is een programmeertaal waar ik steeds vaker en heel graag mee experimenteer. Bekijk enkele projectjes zoals een todo-app en enkele mini-games, in deze ",
  "659": "Python tryouts",
  "660": "Github repo",
  "661": " Python leren? \"Learn Python the Right Way\" is een moderne online adaptatie van het boek \"How to Think Like a Computer Scientist\" met aandacht voor zowel fundamentele concepten als praktische voorbeelden en projecten. ",
  "662": "Learn Python the Right Way",
  "663": "Learn Python the Right Way",
  "664": "PHP",
  "665": " Als webontwikkelaar kom je natuurlijk ook regelmatig in contact met PHP. In het geval van WordPress is PHP de belangrijkste programmeertaal die wordt gebruikt om thema's, plugins en andere functionaliteiten te ontwikkelen. Ik ben vertrouwd met de syntax en meest voorkomende concepten, maar wat server-side applicaties betreft ben ik meer vertrouwd met Node.js. ",
  "666": " IDE's, dev tools en projectbeheer ",
  "667": "IDE",
  "668": " Mijn basisconfiguratie bestaat uit een Windows Subsystem for Linux (WSL2) en Visual Studio Code, aangevuld met verschillende extensies en plugins voor bijvoorbeeld Docker of het automatisch formatteren van code volgens de regels van de kunst met Prettier. En sinds de opmars van AI zijn ook plugins zoals Tabnine AI of Github Co-pilot een toegevoegde waarde om sneller te kunnen werken. ",
  "669": "Versiebeheer",
  "670": " Als webontwikkelaar beheers ik niet alleen de basisprincipes van Git, mijn workflow omvat ook het gebruik van ",
  "671": " met een cryptografische sleutel voor extra beveiliging en het gebruik van een gestandaardiseerd ",
  "672": " om consistente en informatieve commits te (proberen) waarborgen. Voorbeeld op mijn ",
  "673": ". ",
  "674": "signed commits",
  "675": "commit template",
  "676": "Github-pagina",
  "677": " Over Git branching modellen en work flows kan je eindeloos discussiëren, maar één van de meest heldere blog posts is er eentje uit de oude doos: ",
  "678": " door Vincent Driessen (2010). ",
  "679": "A Successful Git Branching Model",
  "680": " Github is uiteraard een uiterst effectieve interface bovenop Git, maar voor mijn individuele projecten gebruik ik ook Gitea waarbij ik de code beheer op mijn eigen server. ",
  "681": "Projectbeheer",
  "682": " Ik ben vertrouwd met Enterprise oplossingen zoals Jira maar voor persoonlijke projecten verkies ik een Kanban workflow via ",
  "683": "Bekijk de code op Github",
  "684": "Bekijk de code op Github",
  "685": " Verberg een tekstbericht in een afbeelding. Codeer en decodeer een afbeelding naar keuze. Gebouwd met Python en React. ",
  "686": "Bekijk de code op Github",
  "687": "Bekijk de code op Github",
  "688": "Bouwen en beheren van Wordpress websites",
  "689": "Naar de blog post van Vincent Driesen",
  "690": "Signal Messenger",
  "691": "Signal contact toevoegen",
  "692": "Naar ProtonMail Website",
  "693": "Download Tor Browser",
  "694": "Download Brave Browser",
  "695": " TOR of Onion links zijn lange combinaties van cijfers en letters die eindigen met een \".onion\". Het adres van Lion Digits kan je herkennen aan de eerste zes karakters: \"http://liondig... .onion\": ",
  "696": "Open deze Onion link in een browser met TOR ondersteuning",
  "697": "Saylor Academy",
  "698": "Certificaat Joris Raymaekers",
  "699": "Website Case Bitcoin",
  "700": "Kritiek op bitcoin",
  "701": "Er is meer...",
  "702": "Sneeuw",
  "703": "IT Diensten",
  "704": "Bitcoin en energie",
  "705": "KPMG rapport over het mijnen van Bitcoin",
  "706": "[KPMG over het 'mijnen' van Bitcoin]",
  "707": "Ook vanuit institutionele en corporatieve hoek blijft de aandacht voor bitcoin toenemen. Steeds meer bedrijven, groot en klein, beseffen dat er naast de wilde prijsschommelingen en alle gekkigheden van 'crypto' speculanten wel degelijk een basis is voor nieuwe en serieuze zakelijke modellen. Dit rapport van KPMG is daar een voorbeeld van:",
  "708": "Bitcoin’s role in the ESG imperative",
  "709": "The Rise of bitcoin",
  "710": "home page",
  "711": "← Home",
  "712": "Home page",
  "713": "← Home",
  "714": " op maat voor jou... ",
  "715": "Krachtige tech",
  "716": " ontwikkeling met speciale aandacht voor best-practices, privacy en beveiliging. ",
  "717": "Pixel-perfecte web app",
  "718": " met de tools van het web: HTML, CSS, JavaScript, ReactJs en NodeJs (of Python). ",
  "719": "Freelance programmeerwerk",
  "720": " Analyse, hulp en advies voor jouw aanwezigheid op het web: ",
  "721": " om je zakelijke impact te vergroten. ",
  "722": "IT als hefboom",
  "723": "Joris Raymaekers",
  "724": "heeft een ruime ervaring in het creëren van relevante en gebruiksvriendelijke web applicaties en helpt graag ondernemers om hun doelen te overtreffen.",
  "725": " Neem contact op of boek meteen een gratis online consultatie om te verkennen hoe we elkaar kunnen versterken. ",
  "726": " Voorbeelden van succesvolle realisaties met ",
  "727": "tevreden klanten...",
  "728": "Beschrijving van de functies van de website",
  "729": "Leer meer over BEM",
  "730": "(BEM)",
  "731": "Pretty Good Privacy (PGP)",
  "732": "of TOR Onion Router diensten die je vindt helemaal onderaan op deze pagina.",
  "733": "Ontdek wat ik weet over versleutelde communicatie...",
  "734": " is een open-source front-end sneeuwvlokken-animatie bibliotheek die je makkelijk kan integreren in een bestaande website. Met een paar eenvoudige lijntjes JavaScript krijg je een HTML5 Canvas-animatie met sneeuwvlokken en tal van opties, inclusief aanpasbare schakelknopen waarmee de bezoeker van de website de animatie kan in- of uitschakelen. Bekijk de uitgebreide beschrijving en documentatie op de ",
  "735": " of bekijk de code in de ",
  "736": "Snowfall-js-plugin",
  "737": "NPM-pagina",
  "738": "Github-repo."
}
