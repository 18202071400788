{
  "address": "Lion Digits\r\nHulshoekstraat 48\r\n3560 Lummen\r\n(BE)",
  "company_num": "BE 0781 815 149",
  "pgp_long_id": "C00A 50D2 30AE 0BD1",
  "pgp_fingerprint": "A6EE 1148 5FF8 3B2E 161B F7AC C00A 50D2 30AE 0BD1",
  "email_jr": "joris@liondigits.com",
  "email_info": "info@liondigits.com",
  "tel_be": "+32468578564",
  "tel_es": "+34665234503",
  "signal": "https://signal.me/#p/+32468578564",
  "whatsapp": "https://wa.me/32468578564",
  "skype": "joris.raymaekers?call",
  "github": "https://github.com/jorishr",
  "codepen": "https://codepen.io/jorishr",
  "x": "https://x.com/liondigits",
  "instagram": "https://instagram.com/liondigits",
  "linkedin": "https://www.linkedin.com/in/joris-raymaekers",
  "url": "https://liondigits.com",
  "onion": "http://liondig7n77kkvnwmg4nyah3as53qi75mgbs3fiua76zmxo7krakdhid.onion",
  "nostr": "npub1upscerwtmtrwru39c2y8u9m0yul3jx022xudhq20afd4zlnwvwgqfnxnud"
}
