{
  "100": "Términos y condiciones",
  "101": "Política de privacidad",
  "102": "Comunicación encriptada",
  "107": "Cuando usas, como yo,",
  "108": "ProtonMail",
  "109": "para tu correo, entonces no tienes que hacer nada especial. El correo electrónico entre usuarios de ProtonMail se cifra automáticamente.",
  "110": "Si utilizas otro proveedor de servicios de correo electrónico, puedes utilizar mi clave pública para cifrar un mensaje. Los 16 caracteres que viste, en la parte inferior de la página de inicio, son parte de la ",
  "111": "huella digital",
  "112": " de mi clave pública. Puedes descargar la clave pública completa a continuación. También puedes copiar mi huella digital completa a través del portapapeles.",
  "113": "¿No estás familiarizado con PGP? ¿Quieres obtener más información sobre cifrar mensajes o archivos?",
  "116": "O reserva una consulta conmigo y te ayudaré en tu camino.",
  "117": "Descargar clave pública",
  "118": "Copia de huella dactilar PGP",
  "119": "Soporte para TOR",
  "120": "Este sitio web también está disponible a través de la red TOR (The Onion Router). Las conexiones a través de TOR hacen que sea muy difícil averiguar su ubicación e identidad. Ganas en privacidad, pero la carga del sitio puede retrasarse.",
  "121": "Para conectarse a través de Tor, utiliza un navegador especial,",
  "122": "Navegador Tor",
  "123": ", o un navegador con soporte incorporado para direcciones Onion como",
  "124": "Brave browser",
  "126": "Este sitio también es accesible a través de la Red Tor:",
  "127": "Logotipo de Lion Digits",
  "128": "Número de empresa",
  "129": "Enviar un correo electrónico a Joris Raymaekers",
  "130": "icono de correo electrónico",
  "131": "Llámame desde Bélgica",
  "132": "Icono de teléfono Bélgica",
  "133": "Llámame desde España",
  "134": "Icono de teléfono España",
  "135": "Contáctame a través de la aplicación Signal Messenger",
  "136": "Logotipo de la aplicación Signal Messenger",
  "137": "Contáctame a través de Skype",
  "138": "logotipo de skype",
  "139": "Ver mi perfil en Linked-In",
  "140": "Logotipo de Linked-In",
  "141": "Ver mi perfil en X",
  "142": "logotipo de X",
  "143": "Mira el código que estoy escribiendo en Github",
  "144": "logotipo de Github",
  "145": "Mira mis creaciones visuales en Codepen",
  "146": "logotipo de Codepen",
  "147": "Copiar al portapapeles",
  "148": "icono copiar al portapapeles",
  "149": "¿Qué es esto?",
  "150": "Icono de signo de interrogación",
  "152": "Logotipo de la aplicación Signal Messenger",
  "153": "icono de enlace externo",
  "154": "Artículo de introducción a PGP",
  "155": "icono de enlace externo",
  "156": "icono de descarga",
  "157": "icono copiar al portapapeles",
  "158": "icono de enlace externo",
  "159": "icono de enlace externo",
  "160": "Haz una cita",
  "162": "icono de traducción",
  "163": "Términos y condiciones",
  "164": "Última actualización el 01-03-2022",
  "165": "Descarga los términos y condiciones de Lion Digits aquí:",
  "166": "Descargar Términos y Condiciones",
  "167": "En esta página puedes leer acerca de los términos y condiciones de Lion Digits. Las descripciones en esta página son indicativas. Descarga los términos y condiciones completos para obtener una descripción completa.",
  "168": "Detalles de la compañía",
  "169": "Lion Digits: Consultor TIC y desarrollador de sitios web, aplicaciones y software web, y diseño gráfico con domicilio social en:",
  "170": "y número de empresa:",
  "171": "Ofertas",
  "172": "Todas las cotizaciones y ofertas de Lion Digits son completamente sin compromiso con respecto al cliente.",
  "173": "Los consejos, propuestas y cotizaciones realizadas por Lion Digits se dirigen personalmente al cliente. Sin el permiso de Lion Digits, no está permitido proporcionarlos para que los inspeccionen otras partes o usarlos para otra cosa que no sea evaluar la oferta.",
  "174": "Lion Digits solo está obligado por las ofertas si el cliente confirma por escrito la aceptación de las mismas dentro de los 14 días.",
  "175": "Se concluye un acuerdo tan pronto como el cliente firma la oferta y Lion Digits la recibe, y tan pronto como se recibe el pago anticipado del 25% del monto total.",
  "176": "Solo si se establece en la cotización que no se espera ningún pago por adelantado, el acuerdo se concluirá tan pronto como la cotización haya sido firmada por el cliente.",
  "177": "Garantía",
  "178": "Un período de garantía de 30 días calendario comienza después de la entrega de un proyecto. Este período de garantía permite solucionar errores o bugs dentro de la oferta.",
  "179": "El derecho a la garantía caduca si el cliente desea acceder a una cuenta que puede realizar cambios estructurales en el sitio web o en el código, como una cuenta de super-usuario de un sitio web, acceso al sistema de archivos central mediante (S)FTP o acceso con derechos de escritura en los sistemas de base de datos utilizados.",
  "180": "Se pueden determinar garantías adicionales en el marco de un contrato de mantenimiento.",
  "181": "Responsabilidades",
  "182": "En el caso de una deficiencia atribuible en el cumplimiento del acuerdo, Lion Digits solo es responsable de la compensación de reemplazo hasta el monto de la factura. Se excluye cualquier responsabilidad de Lion Digits por cualquier otra forma de daño, incluida la compensación por daños indirectos, daños consecuentes o daños debido a la pérdida de ingresos o ganancias.",
  "183": "Precios",
  "184": "Todos los precios indicados no incluyen IVA, a menos que se indique expresamente lo contrario.",
  "185": "Los deseos adicionales del cliente que no se hayan acordado de antemano darán como resultado un trabajo adicional por el cual Lion Digits deberá ser compensado proporcionalmente.",
  "186": "Todos los precios están indexados anualmente, salvo pacto en contrario por escrito entre las partes.",
  "187": "Pago",
  "188": "Después de la finalización de la asignación, Lion Digits enviará una factura por el monto involucrado en el acuerdo, menos cualquier anticipo pagado previamente. El cliente debe pagar el monto adeudado dentro de los 14 días posteriores a la fecha de la factura.",
  "189": "Si el cliente ha excedido el plazo de pago, Lion Digits enviará un recordatorio para el pago. Los costes de un recordatorio, por valor de 30 € sin IVA, serán a cargo del cliente.",
  "190": "Si el cliente no ha cumplido con sus obligaciones dentro de los 14 días siguientes al envío del primer recordatorio, se externalizará el cobro. Todos los gastos resultantes de esto son por cuenta del cliente. Si se trata de un sitio web u otros servicios en línea, estos también se desconectarán después del mismo período de excedencia de 14 días. Los costos por la posibilidad de que el sitio web vuelva a estar en línea después son enteramente para el cliente.",
  "191": "Privacidad",
  "192": "Lion Digits recopila y procesa los datos personales y de la empresa que recibe del cliente con el fin de la ejecución del contrato, gestión de clientes, compras, contabilidad y actividades de marketing directo. Las bases legales son la ejecución de un contrato, el cumplimiento de obligaciones legales y reglamentarias y/o el interés legítimo.",
  "194": "← Inicio",
  "195": "icono de descarga",
  "196": "Lee la política de privacidad",
  "199": "Oye, ¡cuidado!",
  "200": "No deberías estar aquí...",
  "201": "atrás",
  "207": "Haz una cita",
  "211": "Sabios emprendedores",
  "212": "conocen bien la importancia de una presencia web bien diseñada, cómo esa presentación puede atraer a su público objetivo, y convertir a los clientes potenciales en",
  "213": "ingresos adicionales",
  "219": "¿Quieres saber más sobre quién soy? ¿Buscas a alguien con competencias técnicas en JavaScript, TypeScript, NodeJs o ReactJs? ¿Tienes curiosidad por conocer los cerebros detrás de la tecnología? Lea más sobre mi historia y descubra el poder de mis habilidades técnicas.",
  "220": "Leer más...",
  "223": "Vista web",
  "224": "ficha técnica",
  "225": "Se creó un nuevo sitio web para la marca de cerveza Goudster de Halen, con una descripción visual de la larga historia de la cervecería y un módulo para pedidos en línea.",
  "226": "Vista web",
  "227": "La base de clientes de Brasserie Lemmensmolen en Beverlo recibió un impulso considerable después de lanzar un elegante sitio web con un módulo de reserva online.",
  "228": "Vista web",
  "230": "Vista web",
  "231": "archivo técnico",
  "232": "¿Interesado? ¡Hablamos!",
  "233": "Hablamos y nos conocemos online para explorar qué puedo hacer por ti y cómo podemos trabajar juntos. Hazte una cita online aquí.",
  "234": "Haz una cita",
  "235": "Icono de triángulo",
  "236": "Icono de pirámide",
  "237": "icono de reloj de arena",
  "238": "Foto de Joris Raymaekers",
  "239": "logotipo de JavaScript",
  "240": "JavaScript",
  "243": "Logotipo de TypeScript",
  "244": "TypeScript",
  "248": "Video de demostración del sitio web de Catacamp",
  "249": "Visita el sitio web de Catacamp",
  "250": "Catacamp logo",
  "251": "icono de enlace externo",
  "252": "Ver el código en GitHub",
  "253": "logotipo de Github",
  "255": "Video de demostración del sitio web de Goudster",
  "256": "Visite el sitio web de Goudster",
  "257": "Logotipo de estrella dorada",
  "258": "icono de enlace externo",
  "259": "Página de inicio Brasserie Lemmensmolen",
  "260": "Página de inicio Brasserie Lemmensmolen",
  "261": "Visite el sitio web de Brasserie Lemmensmolen",
  "262": "logotipo de Lemmensmolen",
  "263": "icono de enlace externo",
  "265": "Video de demostración del sitio web de la agencia de viajes",
  "266": "Visite el sitio web de Clear View Escape",
  "267": "Logotipo de Clear View Escape",
  "268": "icono de enlace externo",
  "269": "Ver el código en Github",
  "270": "logotipo de Github",
  "272": "Lion Digits, desarrollo web",
  "273": "Política de privacidad",
  "274": "Última actualización el 01-03-2022",
  "275": "| Provisiones generales",
  "276": "En esta página puedes leer qué datos se recopilan cuando utilizas este sitio web o interactúa con Lion Digits, por qué se recopilan estos datos y cómo mejora tu experiencia de usuario.",
  "277": "Esta política de privacidad se aplica a los servicios de Lion Digits. Lion Digits no se hace responsable de las prácticas de privacidad de otros sitios y recursos con los que entre en contacto a través de este sitio web.",
  "278": "Al usar este sitio web o comunicarse con Lion Digits, tú indicas que aceptas esta política de privacidad.",
  "279": "Comunicación",
  "280": "Cuando nos envías correos electrónicos u otros mensajes, podemos retener esos mensajes. Tenga en cuenta que algunos datos (del cliente) deben conservarse con fines administrativos, legales o de seguridad. A veces se te pedirá información personal adicional que sea relevante para la situación en cuestión. Esto hace posible procesar tus preguntas y responder a tus solicitudes. Estos datos se almacenan en los servidores seguros propios de Lion Digits o en los de un tercero.",
  "281": "Galletas",
  "282": "Cookies funcionales",
  "283": "Este sitio web utiliza \"cookies\", pequeños archivos de texto que se colocan en tu ordenador para ayudar a que el sitio web funcione mejor y más rápido. Por ejemplo, para recordar la elección del idioma.",
  "284": "La mayoría de los navegadores están configurados para aceptar cookies de forma predeterminada, pero puedes restablecer tu navegador para rechazar todas las cookies o para indicar cuándo se envía una cookie. Sin embargo, es posible que algunas características y servicios de este y otros sitios web no funcionen correctamente si las cookies están deshabilitadas en tu navegador.",
  "285": "Cookies analíticas",
  "286": "Las cookies también se pueden utilizar para analizar cómo los usuarios utilizan el sitio. La información que genera la cookie acerca de tu uso del sitio web puede transferirse al servidor seguro propio de Lion Digits o de un tercero. Esta información se utiliza para realizar un seguimiento de cómo utilizas el sitio web y para compilar informes sobre la actividad del sitio web.",
  "287": "Esta información solo se utilizará para los fines descritos en esta política de privacidad.",
  "288": "Contenido incrustado de otros sitios",
  "289": "La información en este sitio puede contener contenido incrustado (por ejemplo, videos, imágenes, mensajes, etc.). El contenido incrustado de otros sitios se comporta exactamente igual que si el visitante hubiera visitado este otro sitio. Estos sitios pueden recopilar datos sobre ti, usar cookies, incrustar un seguimiento adicional de terceros y monitorear tu interacción con ese contenido incrustado, incluida la grabación de interacciones con el contenido incrustado en este sitio.",
  "290": "Acceder a información personal y datos de contacto",
  "291": "Todos los visitantes tienen la oportunidad de ver, cambiar o eliminar toda la información personal proporcionada a Lion Digits.",
  "292": "correo electrónico",
  "293": "← Inicio",
  "296": "Acerca de mí...",
  "297": "Joris Raymaekers",
  "301": "Libros, rosas y libertad",
  "302": "Un día por las calles de Barcelona para medir la temperatura del proceso político catalán.",
  "303": "Leer en Doorbaak.be",
  "305": "Desarrollar tecnología y usarla como palanca para aumentar tu impacto personal o comercial es algo en lo que me complace ayudar.",
  "312": "Del diseño al código",
  "313": "desarrollo front-end",
  "314": "Página Github",
  "316": "ejemplo",
  "317": "ejemplo",
  "318": "ejemplo",
  "321": "Los diseños y las animaciones visuales se pueden realizar en todos los colores y configuraciones posibles. Puedes encontrar una selección de ejemplos y experimentos en mi Codepen:",
  "322": "Mini-proyectos front-end en Codepen",
  "323": "Las colecciones incluyen: formularios con validación del lado del cliente, galerías de imágenes con filtrado de elementos DOM, modales y animaciones CSS...",
  "324": "Perfil Codepen",
  "333": "Gestión de servidores y servicios en la nube",
  "334": "magia detrás de escena",
  "337": "ejemplo",
  "338": "ejemplo",
  "339": "ejemplo",
  "343": "entorno de desarrollo",
  "344": "Las aplicaciones se desarrollan utilizando un entorno de desarrollo o IDE, Integrated Development Environment. Una colección de programas y herramientas para escribir y probar tu código.",
  "351": "Zona de diversión",
  "352": "Bien, basta con tanta lectura. A continuación encontrarás una serie de mini proyectos y juegos que te mantendrán entretenido durante un tiempo. ¡Que te diviertas!",
  "353": "Adivina la bandera",
  "354": "Juega ahora",
  "355": "Administrador de tareas",
  "356": "Ir a la aplicación",
  "357": "Stenographer",
  "358": "Ir a la aplicación",
  "359": "Patatap",
  "360": "Juega ahora",
  "361": "Al jugador se le presenta una bandera de país aleatoria y cuatro posibles respuestas. Intenta elegir el correcto. Creación de gestión del estado del juego con ReactJs y extracción de datos de la API REST-Countries.",
  "362": "Aplicación de gestión de tareas de una sola página con API JSON personalizada. Función principal de la interfaz de usuario: seleccione varios elementos (tecla Mayús), elimine y actualice una selección personalizada de tareas. Aplicación construida con NodeJs, Express y MongoDb. Puntos finales de API para solicitudes GET/POST/PUT/DELETE. Solicitudes AJAX con jQuery.",
  "364": "Toque, haz clic o escribe al azar en tu teclado. Cada pulsación de tecla produce un fuego artificial de colores y sonidos generados aleatoriamente. Aplicación construida con las bibliotecas PaperJs y HowlerJs.",
  "368": "Foto de Joris Raymaekers",
  "369": "Visite el archivo de juegos de MS-DOS",
  "370": "icono de enlace externo",
  "371": "Casa Pedrera Barcelona",
  "372": "Logotipo de Doorbraak",
  "373": "logotipo de JavaScript",
  "374": "JavaScript",
  "377": "Logotipo de TypeScript",
  "378": "TypeScript",
  "381": "Ves a la página en Github",
  "382": "icono de enlace externo",
  "383": "Ver un ejemplo en Github",
  "384": "icono de enlace externo",
  "385": "Ver un ejemplo en Github",
  "386": "icono de enlace externo",
  "387": "Ver un ejemplo en Github",
  "388": "icono de enlace externo",
  "389": "Ver un ejemplo en Github",
  "390": "icono de enlace externo",
  "392": "Instantánea del perfil de Codepen",
  "393": "logotipo de Codepen",
  "394": "Ver fragmento de código en mi perfil de Github",
  "395": "icono de enlace externo",
  "396": "Ver fragmento de código en mi perfil de Github",
  "397": "icono de enlace externo",
  "398": "Ver fragmento de código en mi perfil de Github",
  "399": "icono de enlace externo",
  "401": "Adivina el país por la bandera",
  "402": "Detalles técnicos...",
  "403": "Icono de signo de interrogación",
  "405": "Imagen de la aplicación Administrador de tareas",
  "406": "Detalles técnicos...",
  "407": "Icono de signo de interrogación",
  "409": "Imagen de la aplicación Stenographer",
  "410": "Detalles técnicos...",
  "411": "Icono de signo de interrogación",
  "413": "Imagen de la aplicación Patatap",
  "414": "Detalles técnicos...",
  "415": "Icono de información",
  "418": "Leer más sobre la política de privacidad,",
  "419": "Puede enviar preguntas o solicitudes relacionadas con el procesamiento de sus datos a través de",
  "420": "o envíe una carta a la siguiente dirección.",
  "422": "Lion Digits | Potente tecnología digital. Por Joris Raymaekers.",
  "423": "aquí",
  "424": "Asesoramiento personalizado para tu presencia en la web",
  "426": "Desarrollo, mantenimiento y optimización de sitios web.",
  "427": "Aplicaciones web multiplataforma con ReactJs y React Native",
  "428": "Desarrollo de aplicaciones de servidor con NodeJs",
  "430": "Configuración de Nginx:",
  "431": "Configuración de composición de Docker:",
  "432": "Script Bash para actualizaciones automáticas de código al servidor VPS:",
  "435": "Taiga",
  "436": "icono de enlace externo",
  "437": "hasta que eso también cambia.",
  "440": "icono de enlace externo",
  "441": "Taiga.io, software de gestión de proyectos ágiles de código abierto",
  "443": "Versión 1.0,",
  "444": "Versión 1.0,",
  "445": "(1983, Herk-de-Stad)",
  "447": "imagen de servicios digitales",
  "453": "Website Text Translator (WTT)",
  "454": "Consulte el módulo NPM en NPMjs.com",
  "455": "WTT",
  "459": "Ver el código en Github",
  "461": "Demo website van CataCamp",
  "463": "Lee el artículo completo en el sitio web de Doorbraak...",
  "464": "Correo, Texto, Teléfono, etc.",
  "465": "otras opciones...",
  "466": "Servicios de TI y sobre mí",
  "467": "Soluciones digitales a medida",
  "468": " ¿Necesitas un sitio web o una aplicación? ¿Buscas un hombre de TI freelance? Aquí lo encontrarás!",
  "474": "vídeo de demostración",
  "475": "vídeo de demostración",
  "476": "vídeo de demostración",
  "477": "Signal",
  "481": "ProtonMail",
  "483": "PGP: Pretty Good Privacy",
  "484": " Este sitio web utiliza cookies para proporcionar una experiencia óptima. ",
  "485": "Más información...",
  "486": "Entendido",
  "487": "icono de negocio",
  "488": "icono de llave",
  "489": "Icono de red Tor",
  "490": "URL del servicio Onion",
  "491": "Mateo 6",
  "492": "9 Vosotros pues, oraréis así: Padre nuestro que estás en los cielos, santificado sea tu nombre. 10 Venga tu reino. Sea hecha tu voluntad, como en el cielo, así también en la tierra. 11 Danos hoy nuestro pan cotidiano. 12 Y perdónanos nuestras deudas, como también nosotros perdonamos á nuestros deudores. 13 Y no nos metas en tentación, mas líbranos del mal: porque tuyo es el reino, y el poder, y la gloria, por todos los siglos. Amén.",
  "496": "Descubre lo que sé de bitcoin... ",
  "499": " Pero el mundo digital no lo es todo y ya se dijo hace tiempo: ",
  "500": ". Por eso, en mi tiempo libre me encontrarás regularmente paseando por la naturaleza con el perro y el entrenamiento de fuerza me mantiene en forma y fuerte. A veces también me gusta chutar una pelota.",
  "501": "Una mente sana en un cuerpo sano",
  "502": " En un capítulo anterior estuve activo como empleado de logística; luego el amor me llevó a Cataluña; y un Master en Política Comparada me llevó a un episodio en el periodismo. Para aquellos a los que les gusta leer y, como yo, tienen un gran corazón por Cataluña, a continuación encontrarán una selección de publicaciones mías de antaño:",
  "503": "Casa de las Pinchas, Barcelona",
  "504": " Los deberes de los catalanes (Parte I)",
  "505": " Joris Raymaekers, uno de nuestros empleados de Doorbraak en Barcelona, mide la temperatura política en Cataluña.",
  "507": "Parlamento de Cataluña, hemiciclo",
  "508": " Los deberes de los catalanes (Parte II)",
  "509": " El actual proceso catalán de transición nacional está transformando gradualmente el panorama político catalán.",
  "512": "Leer más...",
  "513": " Aplicación web para el listado de parcelas de camping en Cataluña. Este proyecto cubre toda la 'full web stack': una API de backend con base de datos y una aplicación web de front-end. Haz clic en la hoja técnica para obtener más detalles.",
  "514": " Sitio web para la agencia de viajes Clear View Escape. Haz clic en la hoja técnica para obtener más detalles.",
  "515": "Ver mi perfil en Nostr",
  "516": "logotipo de nostr",
  "517": "Ver mi perfil en Instagram",
  "518": "logotipo de instagram",
  "519": " La forma más sencilla de enviar mensajes cifrados es a través de la aplicación Signal Messenger: ",
  "520": "Signal",
  "521": " Esta aplicación de mensajería gratuita y de código abierto ofrece cifrado de extremo a extremo para enviar mensajes, fotos, vídeos y llamadas. Signal solo solicita su número de teléfono cuando se registra por primera vez en el servicio. No se almacenan metadatos relacionados con las comunicaciones. La aplicación también permite que los mensajes se borre automáticamente una vez leídos.",
  "522": "Agrégame como contacto en Signal a través de este enlace:",
  "523": "Contacto de Signal",
  "524": "Envíame un",
  "525": "correo electrónico",
  "526": "Lee uno aquí",
  "527": "introducción",
  "528": "Logotipo de Bitcoin aceptado aquí",
  "529": "Logotipo de Bitcoin aceptado aquí",
  "530": "Logotipo de bitcoin",
  "531": "Logotipo de bitcoin",
  "532": "¿Pagar en bitcoin? ¡Sí se puede!",
  "533": " Bitcoin es un experimento interesante y una forma diferente de manejar el dinero. Me parece muy interesante seguirlo tanto desde el punto de vista técnico como monetario y, por tanto, estoy dispuesto a aceptarlo como medio de pago.",
  "534": " Bitcoin es accesible para todos, pero probablemente no sea del agrado de todos. ",
  "535": ".",
  "536": "No soy un promotor de bitcoin ni un asesor financiero y nunca contactaré ni hablaré espontáneamente con nadie al respecto",
  "537": " Circula mucha información confusa e incorrecta sobre bitcoin y lo que se llama 'cripto'. Por favor infórmate bien y si buscas un punto de partida, humildemente te hago dos sugerencias gratuitas:",
  "538": "Bitcoin para todos -",
  "539": "[Academia Saylor]",
  "540": " Bitcoin for Everybody es un curso en inglés (12 horas) con una buena descripción general de la historia, las ideas básicas y los conceptos que rodean a bitcoin. Después de completar el curso puedes obtener un certificado como este: Saylor Academy",
  "541": "certificado",
  "542": "Bitcoin es... —",
  "543": "[Case Bitcoin]",
  "544": " Se puede abordar bitcoin desde múltiples ángulos: oro digital, un gran avance en informática, una moneda de y para Internet, un experimento económico, una inversión especulativa, etc. CaseBitcoin defiende bitcoin desde diferentes perspectivas y también tiene una descripción general útil de las preocupaciones más comunes y",
  "545": "críticos",
  "546": " El documento con que empezó todo ",
  "547": ", del anónimo Satoshi Nakamoto encontrarás ",
  "548": ".",
  "549": "Bitcoin: A Peer-to-Peer Electronic Cash System",
  "550": "en este pdf",
  "551": "Logotipo de React",
  "552": "React",
  "553": "Logotipo de Node.js",
  "554": "Node.js",
  "555": "Mi primera experiencia con los ordenadores y el código informático viene de arreglar videojuegos en la línea de comandos de MS-DOS. ¿Nunca has oído hablar de MS-DOS? Eso entonces funcionó ",
  "556": "así...",
  "557": " Lo que comenzó como un pasatiempo de configurar juegos y reparar PC y teléfonos ahora ha evolucionado hasta convertirse en la creación de aplicaciones web completas.",
  "558": " Pero no trabajo con ordenadores solo para mi mismo. Obtengo la mayor satisfacción y placer al ayudar a otras personas a aliviar sus preocupaciones de TI.",
  "559": " Además de mi trabajo en la web, también me fascina la criptografía y las posibles aplicaciones con claves digitales para la seguridad o nuevas formas de dinero y pagos como ",
  "560": "bitcóin",
  "561": ". De ahí las referencias a cosas como ",
  "562": "Qué puedo hacer por ti",
  "563": " Asesoramiento personalizado para garantizar que tu presencia en línea coincida exactamente con tus objetivos y visión.",
  "564": " Crea aplicaciones web dinámicas y modernas utilizando el extenso ecosistema de bibliotecas de JavaScript y las últimas herramientas digitales.",
  "566": " Experiencia en la creación y gestión de sitios web de WordPress con DIVI y WP-Bakery para una experiencia fluida y flexible.",
  "567": " Aplicaciones web multiplataforma potentes y fáciles de usar que utilizan las últimas tecnologías como React y React Native.",
  "568": " Aprovecha la velocidad y la escalabilidad de Node.js para crear aplicaciones de servidor sólidas con JavaScript.",
  "569": " ¿Tienes inclinaciones técnicas? Continua más abajo para ver una descripción más detallada de mis habilidades técnicas. Mira algunos ejemplos, sigue los enlaces de Github al código fuente o explore las creaciones destacadas en Codepen...",
  "570": "Habilidades técnicas",
  "571": " Una visión general de mis competencias técnicas.",
  "572": "Icono de código",
  "573": "Icono de código",
  "574": "Icono de rama de Git",
  "575": "Icono de rama de Git",
  "576": "Icono de portátil",
  "577": "Icono de portátil",
  "578": "icono de archivo",
  "579": "icono de archivo",
  "580": "Desarrollo front-end",
  "581": "Desarrollo de back-end",
  "582": "Servidores y servicios cloud",
  "583": "Lenguajes de programación",
  "584": "IDE y gestión de proyectos",
  "585": "Implementación de UI/UX",
  "586": " Muchos sitios web — incluido este mismo sitio, se diseñan con un programa de diseño como AdobeXd o Figma y luego se convierten al código de la web en HTML5, (S)CSS y JavaScript. Se presta especial atención a un ",
  "587": " enfoque y una división lógica del código en secciones y componentes con una methodologie como Block-Element-Modifier ",
  "588": "mobile-first",
  "589": "Puedes encontrar un ejemplo de cómo se hace esto en la práctica en el código fuente de este sitio web aquí: ",
  "590": "Bibliotecas de front-end y JavaScript",
  "591": " El desarrollo es (a veces) más rápido si puedes aprovechar el trabajo de otros. Las bibliotecas contienen componentes, diseños y funciones pre-programados y probados que son fácilmente re-utilizables. Por ejemplo, las animaciones de desplazamiento de este sitio web funcionan con la biblioteca AOS (Animation-On-Scroll). Algunos otros ejemplos de bibliotecas con las que estoy familiarizado:",
  "592": "Agrupar y optimizar",
  "593": " Administrar todo el código de diseño, las imágenes y los scripts que vienen con un sitio web importante puede convertirse rápidamente en una tarea desalentadora. Para gestionar y optimizar todas estas fuentes utilizo herramientas especializadas como ParcelJS, Webpack o GulpJS. Mira un ejemplo con GulpJS en",
  "594": "GitHub",
  "595": "React",
  "596": " Las interfaces de usuario interactivas modernas pueden volverse bastante complejas y, a menudo, se necesita una biblioteca de JavaScript avanzada como React, que intenta hacer que la creación y la gestión de componentes de la interfaz de usuario sean eficientes y escalables.",
  "597": "Mira un ejemplo sencillo en",
  "598": "mi Github",
  "599": "O mira la versión online a través de este",
  "600": "Prueba la aplicación Cooking-react",
  "601": "enlace",
  "602": " Aplicaciones Server-side y API's",
  "603": " desarrollo con Node.js",
  "604": " Node.js es un sólido entorno de ejecución de código abierto para JavaScript. Es un entorno muy versátil para crear aplicaciones que luego se pueden ejecutar en prácticamente cualquier plataforma. JavaScript es el lenguaje de la web y, a través de Node.js, puedes utilizar el mismo lenguaje de programación tanto para la interfaz de usuario (UI) como para la lógica del servidor o las tareas de backend.",
  "605": " En combinación con TypeScript, un superconjunto de JavaScript, Node.js te permite crear programas muy sólidos y de alto rendimiento. Desde aplicaciones web y API REST hasta aplicaciones de línea de comandos y soluciones de prueba.",
  "606": "Ejemplos de proyectos Node.js",
  "607": " es un módulo NPM de Node.js con una interfaz de línea de comandos. El programa te ayuda a traducir el texto de archivos HTML existentes a través de la API de Google Translate y luego integrarlos y administrarlos en tu sitio web mediante archivos JSON. La interfaz CLI está construida con Commander y el análisis de elementos HTML se realiza con Node-HTML-Parser. Este programa, con la documentación adjunta, está disponible como software de código abierto en el registro de NPM: ",
  "608": ".",
  "609": " es un proyecto hecho en Node.js con un servidor ExpressJS, una API REST, autenticación con PassportJS y Redis para almacenamiento de sesiones. Otros datos se almacenan en una base de datos MongoDB utilizando Mongoose para modelado de objetos (ODM). Mira el código en ",
  "610": " o una versión de demostración funcional a través de esta ",
  "611": "CataCamp",
  "612": "GitHub",
  "613": "página de Catacamp",
  "614": "Configuración de dominio y DNS",
  "615": " Tu aplicación web debe ser accesible a través de un servidor y un nombre de dominio. Configurar nombres de dominio y configuraciones DNS (Sistema de nombres de dominio) con subdominios, correo electrónico y certificados SSL es una tarea que viene con cada sitio web. Para lograr un alcance óptimo y eficiente del sitio web, también se puede configurar una CDN (Content Delivery Network).",
  "616": "Gestión de servidores",
  "617": " La administración de servidores y sistemas no es mi actividad principal, pero tengo experiencia en configurar y mantener servidores virtuales (VPS) tanto para aplicaciones Node.js como para sitios web Wordpress. Trabajar y configurar instrumentos como Nginx (proxy inverso), gestor de procesos PM2, sesiones SSH de Tmux o contenedores Docker es parte de mis habilidades.",
  "618": " Cuando trabajas en la línea de comandos y en los servidores, a menudo encuentras los mismos tipos de tareas que se pueden automatizar utilizando scripts Bash y trabajos cron. Puedes encontrar algunos ejemplos de scripts y configuraciones de Bash a través de los enlaces siguientes:",
  "619": "Funciones y servicios de la nube",
  "620": ", ",
  "621": ", ",
  "622": ", o de ",
  "623": " Cloud functions forman parte de las \"arquitecturas sin servidor\", en las que la gestión de la infraestructura del servidor se subcontrata a servicios en la nube como, por ejemplo,",
  "624": "A AWS Amazon Lambda",
  "625": "Amazon AWS Lambda",
  "626": "Google Cloud Functions",
  "627": "Google Cloud Functions",
  "628": "Microsoft Azure Functions",
  "629": "Microsoft Azure Functions",
  "630": "Netlify",
  "631": "Netlify",
  "632": ". Los beneficios incluyen escalabilidad, rentabilidad y mantenimiento reducido, pero",
  "633": "Los beneficios y desventajas reales de las funciones en la nube",
  "634": "los contras",
  "635": "incluyen la dependencia del proveedor y posibles desafíos con la latencia y la depuración.",
  "636": "Puedes encontrar un ejemplo de una función de nube simple en mi",
  "637": "GitHub",
  "638": "Lenguajes de programación",
  "639": "Logotipo de Python",
  "640": "Logotipo de Python",
  "641": "Logotipo PHP",
  "642": "Logotipo PHP",
  "643": "JavaScript",
  "644": " Mi atención se centra en JavaScript y, por extensión, TypeScript — un superconjunto de JavaScript bien utilizado en el contexto del desarrollo front-end con React.",
  "645": " Aprendí a escribir mis primeras líneas de código JavaScript usando el libro Eloquent JavaScript de Marijn Haverbeke. No es el libro más sencillo para principiantes, pero sí riguroso.",
  "646": "Eloquent JavaScript de Marijn Haverbeke",
  "647": "Eloquent JavaScript",
  "648": " En mi propia página de Github encontrarás varios repositorios con notas, ejercicios y desafíos de programación que dan testimonio de mi propio proceso de aprendizaje:",
  "649": "Notas de JavaScript",
  "650": "Notas de JavaScript",
  "651": "Desafíos de programación I",
  "652": "Desafíos de programación I",
  "653": "— incluido Jest Unit Tests",
  "654": "Desafíos de programación II",
  "655": "Desafíos de programación II",
  "656": "— incluido Jest Unit Tests",
  "657": "Python",
  "658": " Python es elogiado con razón por su elegancia y simplicidad y es un lenguaje de programación con el que disfruto cada vez más experimentar. Mira algunos proyectos, como una aplicación de gestión de tareas y algunos mini-juegos, en este",
  "659": "Pruebas de Python",
  "660": "repositorio de Github",
  "661": " ¿Quieres aprender Python? \"Learn Python the Right Way\" es una adaptación moderna en línea del libro \"How to Think Like a Computer Scientist\" que se centra en conceptos fundamentales, así como ejemplos y proyectos prácticos.",
  "662": "Learn Python the Right Way",
  "663": "Learn Python the Right Way",
  "664": "PHP",
  "665": " Como desarrollador web, también entras en contacto regularmente con PHP. En el caso de WordPress, PHP es el principal lenguaje de programación utilizado para desarrollar temas, complementos y otras funcionalidades. Estoy familiarizado con la sintaxis y los conceptos más comunes, pero cuando se trata de aplicaciones del lado del servidor estoy más familiarizado con Node.js.",
  "666": " IDE, herramientas de desarrollo y gestión de proyectos",
  "667": "IDE",
  "668": " Mi configuración básica consta de un subsistema de Windows para Linux (WSL2) y Visual Studio Code, complementado con varias extensiones y complementos para, por ejemplo, Docker o el formateo automático de código según las reglas del arte con Prettier. Y desde el auge de la IA, complementos como Tabnine AI o Github Co-pilot también han añadido valor para trabajar más rápido.",
  "669": "Control de versiones",
  "670": " Como desarrollador web, no sólo domino los conceptos básicos de Git, mi flujo de trabajo también incluye el uso ",
  "671": " con una clave criptográfica para mayor seguridad y el uso de un estándar ",
  "672": " para (intentar) garantizar commits coherentes e informativos. Ejemplo en mi ",
  "673": ".",
  "674": "signed commits",
  "675": "commit template",
  "676": "página de Github",
  "677": " Puedes discutir infinitamente sobre los modelos de ramificación y flujos de trabajo de Git, pero una de las publicaciones de blog más claras es una de los viejos tiempos: ",
  "678": " de Vincent Driessen (2010).",
  "679": "A successfully Git Branching Model",
  "680": " Por supuesto, Github es una interfaz extremadamente eficaz además por encima de Git, pero para mis proyectos individuales también uso Gitea, donde administro parte del código en mi propio servidor.",
  "681": "Gestión de proyectos",
  "682": " Estoy familiarizado con soluciones empresariales como Jira, pero para proyectos personales prefiero un flujo de trabajo Kanban a través de",
  "683": "Ver el código en Github",
  "684": "Ver el código en Github",
  "685": " Ocultar un mensaje de texto en una imagen. Codifica y decodifica una imagen de tu elección. Construido con Python y React.",
  "686": "Ver el código en Github",
  "687": "Ver el código en Github",
  "688": "Creación y gestión de sitios web en Wordpress.",
  "689": "Mira el blog de Vincent Driesen",
  "690": "Signal Messenger",
  "691": "Agregar contacto de Signal",
  "692": "Al sitio web de ProtonMail",
  "693": "Descargar el navegador Tor",
  "694": "Descargar el navegador Brave",
  "695": " Los enlaces TOR o Onion son combinaciones largas de números y letras que terminan con \".onion\". Puedes reconocer la dirección de Lion Digits por los primeros seis caracteres: \"http://liondig... .onion\":",
  "696": "Abra este enlace de Onion en un navegador compatible con TOR",
  "697": "Academia Saylor",
  "698": "Certificado de Joris Raymaekers",
  "699": "Web de Case Bitcoin",
  "700": "Críticas al bitcoin",
  "701": "Espera, hay más...",
  "702": "Nieve",
  "703": "Servicios TI",
  "704": "Bitcoin y energía",
  "705": "Informe de KPMG sobre la minería de Bitcoin",
  "706": "[KPMG sobre la 'minería' de Bitcoin]",
  "707": "La atención al bitcoin también sigue aumentando desde ángulos institucionales y corporativos. Cada vez más empresas, grandes y pequeñas, se dan cuenta de que, además de las salvajes fluctuaciones de precios y toda la locura de los especuladores de \"criptomonedas\", existe de hecho una base para modelos de negocio nuevos y serios. Este informe de KPMG es un ejemplo de esto:",
  "708": "Bitcoin’s role in the ESG imperative",
  "709": "The Rise of bitcoin",
  "710": "pagina de inicio",
  "711": "← Inicio",
  "712": "pagina de inicio",
  "713": "← Inicio",
  "714": " hecho a tu medida...",
  "715": "Tecnología poderosa",
  "716": " y especial atención a las mejores prácticas, privacidad y seguridad.",
  "717": "Desarrollo de aplicaciones web con perfectas píxeles ",
  "718": " con las herramientas de la web: HTML, CSS, JavaScript, ReactJs y NodeJs (o Python)",
  "719": "Programación freelance.",
  "720": " Análisis, ayuda y consejos para tu presencia web:",
  "721": " para aumentar el impacto de tu negocio.",
  "722": " TI como palanca",
  "723": "Joris Raymaekers",
  "724": "tiene una amplia experiencia en la creación de aplicaciones web relevantes y fáciles de usar y le encanta ayudar a emprendedores a superar sus objetivos.",
  "725": " Pon-te en contacto i reserva una consulta gratuita en línea para explorar cómo podemos fortalecernos mutuamente.",
  "726": "Ejemplos de logros exitosos con ",
  "727": "clientes satisfechos...",
  "728": "Descripción de las funciones del sitio web",
  "729": "Más información sobre BEM",
  "730": "(BIEN)",
  "731": "Pretty Good Privacy (PGP)",
  "732": "o los servicios TOR Onion Router que encontrarás al final de esta página.",
  "733": "Descubre lo que sé sobre las comunicaciones cifradas...",
  "734": " es una biblioteca de animación de copos de nieve de código abierto que se puede integrar fácilmente en un sitio web existente. Con unas pocas líneas simples de JavaScript, obtienes una animación HTML5 Canvas con copos de nieve y toneladas de opciones, incluidos botones de alternancia personalizables que permiten al visitante del sitio web activar o desactivar la animación. Mira la descripción detallada y la documentación en la ",
  "735": " o consulta el código en el ",
  "736": "Snowfall-js-plugin",
  "737": "página NPM",
  "738": "Repositorio de Github."
}
