{
  "100": "Termes i condicions",
  "101": "Política de privacitat",
  "102": "Comunicació xifrada",
  "107": "Fas servir, com jo",
  "108": "ProtonMail",
  "109": "pel teu correu electrònic? Llavors no cal fer res especial. El correu electrònic entre usuaris de ProtonMail s'encripta automàticament.",
  "110": "Si utilitzes un altre proveïdor de serveis de correu electrònic, pots utilitzar la meva clau pública per xifrar un missatge. Els 16 caràcters que has vist, a la part inferior de la pàgina d'inici, formen part de l'",
  "111": "empremta digital",
  "112": " de la meva clau pública. Pots descarregar la clau pública completa a continuació. També pots copiar la meva empremta digital completa mitjançant el porta-retalls.",
  "113": "No coneixes PGP? Vols obtenir més informació sobre com xifrar missatges o fitxers?",
  "116": "O reserva una consulta amb mi i t'ajudaré en el teu camí.",
  "117": "Descarrega la clau pública",
  "118": "Còpia d'empremta digital PGP",
  "119": "Suport TOR",
  "120": "Aquest lloc web també està disponible a través de la xarxa TOR (The Onion Router). Les connexions mitjançant TOR dificulten molt esbrinar la teva ubicació i identitat. Guanyes en privadesa, però la càrrega del lloc pot ser retardada.",
  "121": "Per connectar-te mitjançant Tor, fas servir un navegador especial,",
  "122": "Navegador Tor",
  "123": ", o un navegador amb suport integrat per a adreces de Onion com",
  "124": "Brave browser",
  "126": "Aquest lloc també és accessible a través de la xarxa Tor:",
  "127": "Logotip de Lion Digits",
  "128": "Número d'empresa",
  "129": "Envia un correu electrònic a Joris Raymaekers",
  "130": "Icona de correu electrònic",
  "131": "Truca'm des de Bèlgica",
  "132": "Icona de telèfon de Bèlgica",
  "133": "Truca'm des d'Espanya",
  "134": "Icona del telèfon Espanya",
  "135": "Contacta amb mi mitjançant l'aplicació Signal Messenger",
  "136": "Logotip de l'aplicació Signal Messenger",
  "137": "Contacta amb mi via Skype",
  "138": "Logotip de Skype",
  "139": "Veure el meu perfil a Linked-In",
  "140": "Logotip d'enllaç",
  "141": "Veure el meu perfil a X",
  "142": "Logotip de X",
  "143": "Fes una ullada al codi que estic escrivint a Github",
  "144": "Logotip de Github",
  "145": "Fes una ullada a les meves creacions visuals a Codepen",
  "146": "Logotip de Codepen",
  "147": "Copiar al porta-retalls",
  "148": "Icona de còpia al porta-retalls",
  "149": "Què és això?",
  "150": "Icona de signe d'interrogació",
  "152": "Logotip de l'aplicació Signal Messenger",
  "153": "icona d'enllaç extern",
  "154": "Article introductori de PGP",
  "155": "icona d'enllaç extern",
  "156": "icona de baixada",
  "157": "Icona de còpia al porta-retalls",
  "158": "icona d'enllaç extern",
  "159": "icona d'enllaç extern",
  "160": "Demana hora",
  "162": "Icona de traducció",
  "163": "Termes i condicions",
  "164": "Última actualització el 01-03-2022",
  "165": "Descarrega els termes i condicions de Lion Digits aquí:",
  "166": "Descarrega els Termes i Condicions",
  "167": "En aquesta pàgina pots llegir els termes i condicions de Lion Digits. Les descripcions d'aquesta pàgina són orientatives. Descarrega els termes i condicions complets per obtenir una visió general completa.",
  "168": "Dades de l'empresa",
  "169": "Lion Digits: consultor TIC i desenvolupador de llocs web, aplicacions web i programari, i disseny gràfic amb domicili social a:",
  "170": "i número d'empresa:",
  "171": "Offertes",
  "172": "Totes les cotitzacions i pressupostos de Lion Digits són totalment sense compromís amb el client.",
  "173": "Els assessoraments, les propostes i les cotitzacions realitzades per Lion Digits es dirigeixen personalment al client. Sense el permís de Lion Digits, no està permès proporcionar-los per a la inspecció a altres parts ni utilitzar-los per avaluar l'oferta.",
  "174": "Lion Digits només està vinculat per les ofertes si el client confirma per escrit l'acceptació de les mateixes en un termini de 14 dies.",
  "175": "Un acord es conclou tan bon punt l'oferta és signada pel client i rebuda per Lion Digits, i tan bon punt s'ha rebut el pagament anticipat del 25% de l'import total.",
  "176": "Només si s'indica a l'oferta que no s'espera cap pagament anticipat, el contracte es conclou tan bon punt el client hagi signat l'oferta.",
  "177": "Garantia",
  "178": "Un període de garantia de 30 dies naturals comença després de l'entrega d'un projecte. Aquest període de garantia permet resoldre errors o errors dins del pressupost.",
  "179": "El dret a la garantia caduca si el client vol accedir a un compte que pugui fer canvis estructurals al lloc web o al codi, com ara un compte de superusuari d'un lloc web, accés al sistema de fitxers principal mitjançant (S)FTP o accés amb drets d'escriptura als sistemes de bases de dades utilitzats.",
  "180": "Les garanties addicionals es poden determinar en el marc d'un contracte de manteniment.",
  "181": "Responsabilitats",
  "182": "En cas d'una deficiència imputable en el compliment de l'acord, Lion Digits només és responsable de la compensació de substitució fins a l'import de la factura. S'exclou qualsevol responsabilitat de Lion Digits per qualsevol altra forma de dany, inclosa la compensació per danys indirectes, danys conseqüents o danys per pèrdua de facturació o beneficis.",
  "183": "Preus",
  "184": "Tots els preus indicats no inclouen l'IVA, llevat que s'indiqui expressament el contrari.",
  "185": "Els desitjos addicionals del client que no s'hagin acordat prèviament donaran lloc a un treball addicional pel qual Lion Digits haurà de ser compensat proporcionalment.",
  "186": "Tots els preus estan indexats anualment, llevat que les parts s'acordin el contrari per escrit.",
  "187": "Pagament",
  "188": "Un cop finalitzada l'encàrrec, Lion Digits enviarà una factura per l'import implicat en l'acord, menys qualsevol avançament pagat prèviament. El client haurà d'abonar l'import degut dins dels 14 dies següents a la data de la factura.",
  "189": "Si el client ha superat el termini de pagament, Lion Digits enviarà un recordatori de pagament. Els costos d'un recordatori, per valor de 30,00 € IVA exclòs, seran a càrrec del client.",
  "190": "Si el client no ha complert les seves obligacions en els 14 dies següents a l'enviament del primer recordatori, el cobrament serà subcontractat. Tots els costos derivats d'això són per compte del client. Si es tracta d'un lloc web o altres serveis en línia, aquests també es desconnectaran després del mateix període superior a 14 dies. Els costos per tornar a connectar el lloc web després són totalment per al client.",
  "191": "Privadesa",
  "192": "Lion Digits recull i tracta les dades personals i de l'empresa que rep del client amb la finalitat de l'execució del contracte, gestió de clients, compres, comptabilitat i activitats de màrqueting directe. Els fonaments legals són l'execució d'un acord, el compliment d'obligacions legals i reglamentàries i/o l'interès legítim.",
  "194": "← Inici",
  "195": "icona de baixada",
  "196": "Llegeix la política de privadesa",
  "199": "Ei, compte!",
  "200": "No hauries d'estar aquí...",
  "201": "Torna",
  "207": "Demanar hora",
  "211": "Emprenedors intel·ligents",
  "212": "saben de la importància d'una presència web ben dissenyada, de com aquesta presentació arribarà al seu públic objectiu i convertirà els clients potencials en",
  "213": "ingressos addicionals",
  "219": "Vols saber més sobre qui sóc? Busques algú amb competències tècniques en JavaScript, TypeScript, NodeJs o ReactJs? Tens curiositat pel cervell darrere de la tecnologia? Llegeix més sobre la meva història i descobreix el poder de les meves habilitats tècniques.",
  "220": "Llegeix més...",
  "223": "Visita lloc web",
  "224": "fitxa tècnica",
  "225": "Es va crear un nou lloc web per a la marca de cervesa Goudster de Halen, amb una visió general pràctica de la llarga història de la cerveseria i un mòdul per a comandes en línia.",
  "226": "Visita lloc web",
  "227": "La base de clients de la Brasserie Lemmensmolen de Beverlo va obtenir un impuls considerable després de llançar un elegant lloc web amb un mòdul de reserves en línia.",
  "228": "Visita lloc web",
  "230": "Visita lloc web",
  "231": "fitxa tècnica",
  "232": "Tens interès? Parlem!",
  "233": "Ens coneixem online i explorem com puc ajudar-te i com podem treballar junts. Fes-te una cita online aquí.",
  "234": "Demana hora",
  "235": "Icona de triangle",
  "236": "Icona de piràmide",
  "237": "Icona de rellotge de sorra",
  "238": "Foto de Joris Raymaekers",
  "239": "Logotip de JavaScript",
  "240": "JavaScript",
  "243": "Logotip de TypeScript",
  "244": "TypeScript",
  "248": "Video de demostración del sitio web de Catacamp",
  "249": "Visita la web del Catacamp",
  "250": "Catacamp logo",
  "251": "icona d'enllaç extern",
  "252": "Consulta el codi a GitHub",
  "253": "Logotip de Github",
  "255": "Vídeo de demostració del lloc web de Goudster",
  "256": "Visiteu el lloc web de Goudster",
  "257": "Logotip de Goudster",
  "258": "icona d'enllaç extern",
  "259": "Pàgina d'inici Brasserie Lemmensmolen",
  "260": "Pàgina d'inici Brasserie Lemmensmolen",
  "261": "Visita el lloc web de Brasserie Lemmensmolen",
  "262": "Logotip de Lemmensmolen",
  "263": "icona d'enllaç extern",
  "265": "Vídeo de demostració del lloc web de l'agència de viatges",
  "266": "Visita el lloc web Clear View Escape",
  "267": "Logotip de Clear View Escape",
  "268": "icona d'enllaç extern",
  "269": "Veure el codi a Github",
  "270": "Logotip de Github",
  "272": "Lion Digits, desenvolupament web",
  "273": "Política de privacitat",
  "274": "Última actualització el 01-03-2022",
  "275": "| Provisions generals",
  "276": "En aquesta pàgina pots llegir quines dades es recullen quan utilitzes aquest lloc web o interactues amb Lion Digits, per què es recullen aquestes dades i com milloren la teva experiència d'usuari.",
  "277": "Aquesta política de privadesa s'aplica als serveis de Lion Digits. Lion Digits no es fa responsable de les pràctiques de privadesa d'altres llocs i recursos amb els quals entras en contacte a través d'aquest lloc web.",
  "278": "En utilitzar aquest lloc web o en comunicar-se amb Lion Digits, indiques l'acceptació d'aquesta política de privadesa.",
  "279": "Comunicació",
  "280": "Quan ens envies un correu electrònic o altres missatges, podem conservar aquests missatges. Ten en compte que algunes dades (del client) s'han de conservar amb finalitats administratives, legals o de seguretat. De vegades, se te demanarà informació personal addicional que sigui rellevant per a la situació en qüestió. Això fa possible processar les teves preguntes i respondre les vostres sol·licituds. Aquestes dades s'emmagatzemen als servidors segurs propis de Lion Digits o d'un tercer.",
  "281": "Galetes",
  "282": "Cookies funcionals",
  "283": "Aquest lloc web utilitza \"cookies\", petits fitxers de text que es col·loquen al vostre ordinador per ajudar-lo a funcionar millor i més ràpid. Per exemple, per recordar l'elecció de l'idioma.",
  "284": "La majoria dels navegadors estan configurats per acceptar galetes de manera predeterminada, però pots restablir el vostre navegador per rebutjar totes les galetes o per indicar quan s'envia una galeta. Tanmateix, algunes funcions i serveis d'aquest i d'altres llocs web poden no funcionar correctament si les galetes estan desactivades al teu navegador.",
  "285": "Cookies analítiques",
  "286": "Les cookies també es poden utilitzar per analitzar com els usuaris utilitzen el lloc. La informació generada per la cookie sobre el teu ús del lloc web pot ser transferida al servidor segur de Lion Digits o d'un tercer. Aquesta informació s'utilitza per fer un seguiment de com utilitza el lloc web i per compilar informes sobre l'activitat del lloc web.",
  "287": "Aquesta informació només s'utilitzarà per a les finalitats descrites en aquesta política de privadesa.",
  "288": "Contingut incrustat d'altres llocs",
  "289": "La informació d'aquest lloc pot contenir contingut incrustat (per exemple, vídeos, imatges, missatges, etc.). El contingut incrustat d'altres llocs es comporta exactament igual que si el visitant hagués visitat aquest altre lloc. Aquests llocs poden recopilar dades sobre vostè, utilitzar galetes, incrustar un seguiment addicional de tercers i controlar la teva interacció amb aquest contingut incrustat, inclòs l'enregistrament d'interaccions amb el contingut incrustat en aquest lloc.",
  "290": "Accedir a la informació personal i a les dades de contacte",
  "291": "Tots els visitants tenen l'oportunitat de veure, canviar o suprimir tota la informació personal proporcionada a Lion Digits.",
  "292": "correu electrònic",
  "293": "← Inici",
  "296": "Sobre mi...",
  "297": "Joris Raymaekers",
  "301": "Llibres, roses i llibertat",
  "302": "Una jornada pels carrers de Barcelona per mesurar la temperatura del procés polític català.",
  "303": "Llegeix a Doorbaak.be",
  "305": "Desenvolupar tecnologia i utilitzar-la com a palanca per augmentar el teu impacte personal o empresarial és una cosa amb la qual estic encantat d'ajudar-te.",
  "312": "Del disseny al codi",
  "313": "desenvolupament front-end",
  "314": "pàgina Github",
  "316": "exemple",
  "317": "exemple",
  "318": "exemple",
  "321": "Els dissenys i les animacions visuals es poden fer en tots els colors i configuracions possibles. Es pot trobar una selecció d'exemples i experiments al meu Codepen:",
  "322": "Mini-projectes front-end en Codepen",
  "323": "Les col·leccions inclouen: formularis amb validació del costat del client, galeries d'imatges amb filtratge d'elements DOM, modals i animacions CSS...",
  "324": "Perfil Codepen",
  "333": "Gestió de servidors i serveis al núvol",
  "334": "màgia darrere les escenes",
  "337": "exemple",
  "338": "exemple",
  "339": "exemple",
  "343": "entorn de desenvolupament",
  "344": "Les aplicacions es desenvolupen mitjançant un entorn de desenvolupament o IDE, entorn de desenvolupament integrat. Una col·lecció de programes i eines per escriure i provar el teu codi.",
  "351": "Zona de diversió",
  "352": "Bé, prou lectura. A continuació trobaràs una sèrie de mini projectes i jocs que et mantindran entretingut durant una estona. Diverteix-te!",
  "353": "Endevina la bandera",
  "354": "Juga ara",
  "355": "Gestor de tasques",
  "356": "A l'aplicació",
  "357": "Stenographer",
  "358": "A l'aplicació",
  "359": "Patatap",
  "360": "Juga ara",
  "361": "Es presenta al jugador una bandera de país aleatòria i quatre possibles respostes. Intenta triar el correcte. Gestió de l'estat del joc construït amb ReactJs i dades extretes de l'API REST-Countries.",
  "362": "Aplicació de gestió de tasques d'una sola pàgina amb API JSON personalitzada. Característica principal de la interfície d'usuari: seleccioneu diversos elements (tecla majúscules), suprimiu i actualitzeu una selecció personalitzada de tasques. Aplicació construïda amb NodeJs, Express i MongoDb. Punts finals de l'API per a sol·licituds GET/POST/PUT/DELETE. Sol·licituds AJAX amb jQuery.",
  "364": "Toca, fes clic o escribe aleatòriament al teu teclat. Cada pulsació de tecla produeix un foc artificial de sons i colors generats aleatòriament. Aplicació construïda amb les biblioteques PaperJs i HowlerJs.",
  "368": "Foto de Joris Raymaekers",
  "369": "Visita l'arxiu de jocs de MS-DOS",
  "370": "icona d'enllaç extern",
  "371": "Casa Pedrera Barcelona",
  "372": "Logotip de Doorbraak",
  "373": "Logotip de JavaScript",
  "374": "JavaScript",
  "377": "Logotip de TypeScript",
  "378": "TypeScript",
  "381": "",
  "382": "icona d'enllaç extern",
  "383": "Vegeu un exemple a Github",
  "384": "icona d'enllaç extern",
  "385": "Vegeu un exemple a Github",
  "386": "icona d'enllaç extern",
  "387": "Vegeu un exemple a Github",
  "388": "icona d'enllaç extern",
  "389": "Vegeu un exemple a Github",
  "390": "icona d'enllaç extern",
  "392": "Instantània del perfil de Codepen",
  "393": "Logotip de Codepen",
  "394": "Veure el fragment de codi al meu perfil de Github",
  "395": "icona d'enllaç extern",
  "396": "Veure el fragment de codi al meu perfil de Github",
  "397": "icona d'enllaç extern",
  "398": "Veure el fragment de codi al meu perfil de Github",
  "399": "icona d'enllaç extern",
  "401": "Endevina el país a partir de la bandera",
  "402": "Detalls tècnics...",
  "403": "Icona de signe d'interrogació",
  "405": "Imatge de l'aplicació Task Manager",
  "406": "Detalls tècnics...",
  "407": "Icona de signe d'interrogació",
  "409": "Imatge de l'aplicació Stenographer",
  "410": "Detalls tècnics...",
  "411": "Icona de signe d'interrogació",
  "413": "Imatge de l'aplicació Patatap",
  "414": "Detalls tècnics...",
  "415": "Icona d'informació",
  "418": "Llegeix més sobre la política de privadesa,",
  "419": "Pots enviar preguntes o sol·licituds sobre el tractament de les teves dades a través de",
  "420": "o envia una carta a l'adreça següent.",
  "422": "Dígits de lleó | Tecnologia digital potent. Per Joris Raymaekers.",
  "423": "aquí",
  "424": "Assessorament personalitzat per a la vostra presència al web",
  "426": "Desenvolupament, manteniment i optimització de llocs web",
  "427": "Aplicacions web multiplataforma amb ReactJs i React Native",
  "428": "Desenvolupament d'aplicacions de servidor amb NodeJs",
  "430": "Configuració de Nginx:",
  "431": "Configuració de Docker Compose:",
  "432": "Script Bash per a actualitzacions automàtiques de codi al servidor VPS:",
  "435": "Taigà",
  "436": "icona d'enllaç extern",
  "437": "fins que això també canvia.",
  "440": "icona d'enllaç extern",
  "441": "Taiga.io, programari de gestió de projectes àgil de codi obert",
  "443": "Versió 1.0,",
  "444": "Versió 1.0,",
  "445": "(1983, Herk-de-Stad)",
  "447": "imatge dels serveis digitals",
  "453": "Website Text Translator (WTT)",
  "454": "Consulteu el mòdul NPM a NPMjs.com",
  "455": "WTT",
  "459": "Veure el codi a Github",
  "461": "Demo website van CataCamp",
  "463": "Llegeix l'article complet al lloc web de Doorbraak...",
  "464": "Correu, text, telèfon, etc.",
  "465": "altres opcions...",
  "466": "Serveis informàtics i sobre mi",
  "467": "Solucions digitals a mida",
  "468": " Necessites un lloc web o una aplicació? Busques un informàtic freelance? Aquí el trobaràs!",
  "474": "Vídeo de demostració",
  "475": "Vídeo de demostració",
  "476": "Vídeo de demostració",
  "477": "Signal",
  "481": "ProtonMail",
  "483": "PGP: Pretty Good Privacy",
  "484": " Aquest lloc web utilitza cookies per oferir una experiència òptima. ",
  "485": "Més informació...",
  "486": "Entès",
  "487": "icona de negoci",
  "488": "Icona de clau",
  "489": "Icona Tor Network",
  "490": "URL del servei Onion",
  "491": "Mateu 6",
  "492": "9 Vosaltres, pregueu així: Pare nostre que estàs en el cel, santifica el teu nom, 10 vingui el teu Regne, que es faci la teva voluntat aquí a la terra com es fa en el cel. 11 Dóna’ns avui el nostre pa de cada dia; 12 perdona les nostres ofenses, així com nosaltres perdonem els qui ens ofenen;  13 no permetis que caiguem en la temptació, i allibera’ns del mal: perquè teu és el regne, i el poder, i la glòria, per tots els segles. Amén.",
  "496": "Descobreix què sé sobre bitcoin...",
  "499": " Però el món digital no ho és tot i es deia fa temps: ",
  "500": ". Així que en el meu temps lliure em trobaràs regularment caminant per la natura amb el gos i l'entrenament de força em manté en forma i fort. També m'agrada xutar una pilota de vegades.",
  "501": "Una ment sana en un cos sa",
  "502": " En un capítol anterior vaig estar actiu com a empleat de logística; llavors l'amor em va portar cap a Catalunya; i un Màster en Política Comparada va donar lloc a un episodi de periodisme. Per als que els agrada llegir i, com jo, tenen un cor càlid per Catalunya, a continuació trobareu una selecció de les meves publicacions dels vells temps:",
  "503": "Casa de les Punxes, Barcelona",
  "504": " Els deures dels catalans (part I)",
  "505": " Joris Raymaekers, un dels nostres empleats de Doorbraak a Barcelona, mesura la temperatura política a Catalunya.",
  "507": "Parlament de Catalunya, hemicicle",
  "508": " Els deures dels catalans (part II)",
  "509": " El procés català de transició nacional en curs està transformant progressivament el panorama polític català.",
  "512": "Llegeix més...",
  "513": " Aplicació web per a la llista de parcel·les d'acampada a Catalunya. Aquest projecte cobreix tota la \"full web stack\": una API backend amb base de dades i una aplicació web de front-end. Fes clic a la fitxa tècnica per a més detalls.",
  "514": " Lloc web per a l'agència de viatges Clear View Escape. Fes clic a la fitxa tècnica per a més detalls.",
  "515": "Veure el meu perfil a Nostr",
  "516": "Logotip de Nostr",
  "517": "Veure el meu perfil a Instagram",
  "518": "Logotip d'Instagram",
  "519": " La manera més fàcil d'enviar missatges xifrats és mitjançant l'aplicació Signal Messenger: ",
  "520": "Signal",
  "521": " Aquesta aplicació de missatgeria gratuïta i de codi obert ofereix un xifratge d'extrem a extrem per enviar missatges, fotos, vídeos i trucades. Signal només demana el vostre número de telèfon quan us registreu per primera vegada al servei. No s'emmagatzemen metadades al voltant de les comunicacions. L'aplicació també permet que els missatges s'esborrin automàticament un cop llegits.",
  "522": "Afegeix-me com a contacte a Signal mitjançant aquest enllaç:",
  "523": "Contacte de Signal",
  "524": "Envia'm un",
  "525": "correu electrònic",
  "526": "Llegeix una aquí",
  "527": "introducció",
  "528": "Bitcoin acceptat aquí logotip",
  "529": "Bitcoin acceptat aquí logotip",
  "530": "Logotip de Bitcoin",
  "531": "Logotip de Bitcoin",
  "532": "Pagar en bitcoin? Sí que es pot!",
  "533": " Bitcoin és un experiment interessant i una manera diferent de tractar els diners. Em sembla molt interessant de seguir tant des d'una perspectiva tècnica com monetària i, per tant, estic disposat a acceptar-ho com a mitjà de pagament.",
  "534": " Bitcoin és accessible per a tothom, però probablement no la tassa de te de tothom. ",
  "535": ".",
  "536": "No sóc un promotor de bitcoin ni un assessor financer i mai no contactaré ni molestaré espontàniament ningú al respecte.",
  "537": " Hi ha molta informació confusa i incorrecta que circula sobre bitcoin i el que s'anomena \"cripto\". Si us plau, informa't a fons i si estàs buscant un punt de partida, et faig humilment dos suggeriments gratuïts:",
  "538": "Bitcoin per a tothom -",
  "539": "[Acadèmia Saylor]",
  "540": " Bitcoin for Everybody és un curs en anglès (12 hores) amb una bona visió general de la història, les idees bàsiques i els conceptes que envolten el bitcoin. Després de completar el curs pots rebre un certificat com aquest: Saylor Academy",
  "541": "certificat",
  "542": "Bitcoin és...",
  "543": "[Case Bitcoin]",
  "544": " Es pot mirar bitcoin des de múltiples angles: l'or digital, un avenç en informàtica, una moneda d'Internet i per al mon digital, un experiment econòmic, una inversió especulativa, etc. CaseBitcoin defensa el bitcoin des de diferents perspectives i també té una visió general útil de les preocupacions més habituals i",
  "545": "crítics",
  "546": " El document que va començar tot: ",
  "547": ", de l'anònim Satoshi Nakamoto que trobaràs ",
  "548": ".",
  "549": "Bitcoin: A Peer-to-Peer Electronic Cash System",
  "550": "en aquest pdf",
  "551": "Logotip de React",
  "552": "React",
  "553": "Logotip de Node.js",
  "554": "Node.js",
  "555": "La meva primera experiència amb ordinadors i codi informàtic va ser arran de la reparació de videojocs a la línia d'ordres de MS-DOS. No has sentit mai de MS-DOS? Aleshores va ser ",
  "556": "això...",
  "557": " El que va començar com un hobby amb la configuració de jocs i la reparació d'ordinadors i telèfons ara ha evolucionat cap a la creació d'aplicacions web completes.",
  "558": " Però no treballo amb ordinadors per a mi mateix. Tinc la màxima satisfacció i plaer d'ajudar els altres a alleujar els seus problemes informàtics.",
  "559": " A més del meu treball a la xarxa, també em fascina la criptografia i les possibles aplicacions de seguretat amb claus digitals o noves formes de diners i pagaments com ara ",
  "560": "bitcoin",
  "561": ". D'aquí les referències a coses com ",
  "562": "El que puc fer per tu",
  "563": " Assessorament personalitzat per assegurar-te que la teva presència en línia coincideixi exactament amb els teus objectius i la teva visió.",
  "564": " Creació d'aplicacions web dinàmiques i modernes utilitzant l'ampli ecosistema de biblioteques de JavaScript i les últimes eines digitals.",
  "566": " Experiència en la creació i gestió de llocs web de WordPress amb DIVI i WP-Bakery per a una experiència perfecta i flexible.",
  "567": " Aplicacions web multiplataforma potents i fàcils d'utilitzar que utilitzen les últimes tecnologies com React i React Native.",
  "568": " Aprofita la velocitat i l'escalabilitat de Node.js per crear aplicacions de servidor robustes amb JavaScript.",
  "569": " Estàs inclinat tècnicament? Aleshores, continua més enllà per veure més detalls de les meves habilitats tècniques. Consulta alguns exemples, segueix els enllaços de Github al codi font o navega per les creacions destacades a Codepen...",
  "570": "Habilitats tècniques",
  "571": " Una visió general de les meves competències tècniques",
  "572": "Icona de codi",
  "573": "Icona de codi",
  "574": "Icona de branca de Git",
  "575": "Icona de branca de Git",
  "576": "Icona de l'ordinador portàtil",
  "577": "Icona de l'ordinador portàtil",
  "578": "Icona de fitxer",
  "579": "Icona de fitxer",
  "580": "Desenvolupament front-end",
  "581": "Desenvolupament backend",
  "582": "Servidors i serveis cloud",
  "583": "Llenguatges de programació",
  "584": "IDE i gestió de projectes",
  "585": "Implementació UI/UX",
  "586": " Molts llocs web — inclòs aquest mateix lloc web, estan dissenyats amb un programa de disseny com AdobeXd o Figma i després es converteixen al codi del web en HTML5, (S)CSS i JavaScript. Es presta especial atenció a un ",
  "587": " enfocament i una divisió lògica del codi en seccions i components mitjançant una metodologia com Block-Element-Modifier ",
  "588": "mobile first",
  "589": "Un exemple de com es fa això a la pràctica es pot trobar al codi font d'aquest lloc web en la meva ",
  "590": "Biblioteques de front-end i JavaScript",
  "591": " El desenvolupament és (de vegades) més ràpid si pots basar-te en el treball dels altres. Les biblioteques contenen components, dissenys i funcions pre-programats i provats que es poden reutilitzar fàcilment. Per exemple, les animacions de desplaçament d'aquest lloc web són impulsades per la biblioteca AOS (Animation-On-Scroll). Alguns altres exemples de biblioteques que vaig fer servir:",
  "592": "Agrupa i optimitza",
  "593": " Gestionar tot el codi de disseny, les imatges i els scripts que inclouen un lloc web important pot convertir-se ràpidament en una tasca descoratjadora. Per gestionar i optimitzar totes aquestes fonts, faig servir eines especialitzades com ParcelJS, Webpack o GulpJS. Mira un exemple amb GulpJS a",
  "594": "Github",
  "595": "React",
  "596": " Les interfícies d'usuari interactives modernes poden arribar a ser força complexes i sovint necessitaràs una biblioteca de JavaScript avançada com React que intenti fer que la construcció i la gestió dels components de la interfície d'usuari sigui eficient i escalable.",
  "597": "Mira un exemple senzill al ",
  "598": "meu Github",
  "599": "O mira la versió en directe via aquest",
  "600": "A l'aplicació Cooking-react",
  "601": "enllaç",
  "602": " Aplicacions Server-side y API's",
  "603": " desenvolupament amb Node.js",
  "604": " Node.js és un sòlid entorn d'execució de codi obert per a JavaScript. És un entorn molt versàtil per crear aplicacions que després es poden executar en gairebé qualsevol plataforma. JavaScript és l'idioma del web i mitjançant Node.js pots utilitzar el mateix llenguatge de programació tant per a la interfície d'usuari (UI) com per a la lògica del servidor o de les tasques de fons.",
  "605": " En combinació amb TypeScript — un superconjunt de JavaScript, Node.js ens permet crear programes molt sòlids i d'alt rendiment. Des d'aplicacions web i API REST fins a aplicacions de línia d'ordres i solucions de prova.",
  "606": "Exemples de projectes Node.js",
  "607": " és un mòdul NPM Node.js amb una interfície de línia d'ordres. El programa t'ajuda a traduir el text dels fitxers HTML existents mitjançant l'API de Google Translate i, a continuació, a integrar-los i gestionar-los al vostre lloc web mitjançant fitxers JSON. La interfície CLI es construeix amb Commander i l'anàlisi d'elements HTML es fa amb Node-HTML-Parser. Aquest programa, amb la documentació adjunta, està disponible com a programari de codi obert al registre NPM: ",
  "608": ".",
  "609": " és un projecte construït a Node.js amb un servidor ExpressJS, una API REST, autenticació amb PassportJS i Redis per a l'emmagatzematge de sessions. Altres dades s'emmagatzemen en una base de dades MongoDB mitjançant Mongoose per al modelatge d'objectes (ODM). Mira el codi a ",
  "610": " o una versió de demostració mitjançant aquest ",
  "611": "CataCamp",
  "612": "Github",
  "613": "pàgina de Catacamp.",
  "614": "Configuració de domini i DNS",
  "615": " La vostra aplicació web ha de ser accessible mitjançant un servidor i un nom de domini. Configurar els noms de domini i la configuració de DNS (Domain Name System) amb subdominis, correu electrònic i certificats SSL és una tasca que inclou tots els llocs web. Per aconseguir un abast òptim del lloc web, també es pot configurar una CDN (Content Delivery Network).",
  "616": "Gestió del servidor",
  "617": " L'administració de servidors i sistemes no és la meva activitat principal, però sóc habilitat per configurar i mantenir servidors virtuals (VPS) tant per a les aplicacions Node.js com per als llocs web de Wordpress. Treballar i configurar instruments com Nginx (proxy invers), gestor de processos PM2, sessions Tmux SSH o contenidors Docker forma part de les meves habilitats.",
  "618": " Quan treballes a la línia d'ordres i als servidors, sovint et trobes amb el mateix tipus de tasques que es pot automatitzar mitjançant scripts Bash i treballs cron. Trobaràs alguns exemples d'scripts i configuracions de Bash als enllaços següents:",
  "619": "Funcions al núvol i serveis al núvol",
  "620": ", ",
  "621": ", ",
  "622": ", o ",
  "623": " Les funcions del núvol formen part de les \"arquitectures sense servidor\" on la gestió de la infraestructura del servidor s'externalitza a serveis al núvol com ara",
  "624": "A AWS Amazon Lambda",
  "625": "Amazon AWS Lambda",
  "626": "A Google Cloud Functions",
  "627": "Funcions de Google Cloud",
  "628": "A Microsoft Azure Functions",
  "629": "Funcions de Microsoft Azure",
  "630": "Per a Netlify",
  "631": "Netlify",
  "632": ". Els avantatges inclouen escalabilitat, eficiència en costos i manteniment reduït, però",
  "633": "Els avantatges i inconvenients reals de Cloud Functions",
  "634": "els contres",
  "635": "inclouen una dependencia del proveïdor i els possibles reptes amb latència i depuració.",
  "636": "Pots trobar un exemple d'una funció de núvol simple al meu ",
  "637": "Github",
  "638": "Llenguatges de programació",
  "639": "Logotip de Python",
  "640": "Logotip de Python",
  "641": "Logotip de PHP",
  "642": "Logotip de PHP",
  "643": "JavaScript",
  "644": " El meu enfocament se centra en JavaScript i, per extensió, TypeScript — un superconjunt de JavaScript utilitzat habitualment en el context del desenvolupament front-end amb React.",
  "645": " Vaig aprendre a escriure les meves primeres línies de codi JavaScript utilitzant el llibre Eloquent JavaScript de Marijn Haverbeke. No és el llibre per a principiants més senzill, però sí molt rigorós.",
  "646": "Eloquent JavaScript de Marijn Haverbeke",
  "647": "Eloquent JavaScript",
  "648": " A la meva pròpia pàgina de Github trobaràs una sèrie de repositoris amb notes, exercicis i reptes de programació que testimonien el meu propi procés d'aprenentatge:",
  "649": "Notes de JavaScript",
  "650": "Notes de JavaScript",
  "651": "Reptes de programació I",
  "652": "Reptes de programació I",
  "653": "— Inclou Jest Unit Tests",
  "654": "Reptes de programació II",
  "655": "Reptes de programació II",
  "656": "— Inclou Jest Unit Tests",
  "657": "Python",
  "658": " Python és lloat amb raó per la seva elegància i senzillesa i és un llenguatge de programació amb el qual m'agrada experimentar cada cop més. Fes un cop d'ull a alguns projectes com una aplicació per gestionar tasques i alguns mini-jocs, en aquest",
  "659": "Proves de Python",
  "660": "repositori Github",
  "661": " Vols aprendre Python? \"Learn Python the Right Way\" és una adaptació en línia moderna del llibre \"How to Think Like a Computer Scientist\" centrada en conceptes fonamentals, així com exemples pràctics i projectes.",
  "662": "Learn Python the Right Way",
  "663": "Learn Python the Right Way",
  "664": "PHP",
  "665": " Com a desenvolupador web, també entres en contacte regularment amb PHP. En el cas de WordPress, PHP és el principal llenguatge de programació utilitzat per desenvolupar temes, complements i altres funcionalitats. Estic familiaritzat amb la sintaxi i els conceptes més comuns, però quan es tracta d'aplicacions del costat del servidor estic més familiaritzat amb Node.js.",
  "666": " IDE, eines de desenvolupament i gestió de projectes",
  "667": "IDE",
  "668": " La meva configuració bàsica consisteix en un subsistema de Windows per a Linux (WSL2) i Visual Studio Code, complementat amb diverses extensions i complements per, per exemple, Docker o el format automàtic de codi segons les regles de l'art amb Prettier. I des de l'auge de la IA, complements com Tabnine AI o Github Co-pilot també han afegit valor per treballar més ràpidament.",
  "669": "Control de versions",
  "670": " Com a desenvolupador web, no només domino els conceptes bàsics de Git, el meu flux de treball també inclou l'ús de ",
  "671": " amb una clau criptogràfica per a més seguretat i l'ús d'una estandarditzada ",
  "672": " per (intentar) assegurar commits coherents i informatius. Exemple al meu ",
  "673": ".",
  "674": "signed commits",
  "675": "commit template",
  "676": "Github",
  "677": " Podem parlar sense parar sobre els models de ramificació de Git i els fluxos de treball, però una de les publicacions de bloc més clares és una dels vells temps: ",
  "678": " de Vincent Driessen (2010).",
  "679": "A Successful Git Branching Model",
  "680": " Github és, per descomptat, una interfície extremadament eficaç a sobre de Git, però per als meus projectes individuals també faig servir Gitea on gestiono part del codi al meu propi servidor.",
  "681": "Gestió de projectes",
  "682": " Estic familiaritzat amb solucions empresarials com Jira, però per als projectes personals prefereixo un flux de treball Kanban amb ",
  "683": "Veure el codi a Github",
  "684": "Veure el codi a Github",
  "685": " Amaga un missatge de text en una imatge. Codifica i descodifica una imatge que triïs. Creat amb Python i React.",
  "686": "Veure el codi a Github",
  "687": "Veure el codi a Github",
  "688": "Creació i gestió de pàgines web de Wordpress",
  "689": "Llegeix el blog de Vincent Driesen",
  "690": "Signal Messenger",
  "691": "Afegeix un contacte de Signal",
  "692": "Al lloc web de ProtonMail",
  "693": "Descarrega el navegador Tor",
  "694": "Descarrega el navegador Brave",
  "695": " Els enllaços TOR o Onion són combinacions llargues de números i lletres que acaben amb \".onion\". Pots reconèixer l'adreça de Lion Digits pels sis primers caràcters: \"http://liondig... .onion\":",
  "696": "Obre aquest enllaç Onion en un navegador amb suport TOR",
  "697": "Acadèmia Saylor",
  "698": "Certificat de Joris Raymaekers",
  "699": "Lloc web de Case Bitcoin",
  "700": "Crítiques al bitcoin",
  "701": "Espera, hi ha més...",
  "702": "Neu",
  "703": "Serveis TI",
  "704": "Bitcoin i energia",
  "705": "Informe de KPMG sobre la mineria de Bitcoin",
  "706": "[KPMG sobre la 'mineria' de bitcoin]",
  "707": "L'atenció al bitcoin també continua augmentant des dels angles institucionals i corporatius. Cada cop més empreses, grans i petites, s'adonen que, a més de les fluctuacions salvatges dels preus i de tota la bogeria dels especuladors \"criptogràfics\", sí que hi ha una base per a models de negoci nous i seriosos. Aquest informe de KPMG n'és un exemple:",
  "708": "Bitcoin’s role in the ESG imperative",
  "709": "The Rise of bitcoin",
  "710": "pàgina d'inici",
  "711": "← Inici",
  "712": "Pàgina d'inici",
  "713": "← Inici",
  "714": " fet a mida per a tu...",
  "715": "Tecnologia potent",
  "716": " i especial atenció a les millors pràctiques, privadesa i seguretat.",
  "717": "Desenvolupament d'aplicacions web amb perfectes píxels",
  "718": " amb les eines del web: HTML, CSS, JavaScript, ReactJs i NodeJs (o Python).",
  "719": "Treball de programació autònom",
  "720": " Anàlisi, ajuda i assessorament per a la teva presència al web:",
  "721": "  per augmentar l'impacte del teu negoci.",
  "722": "la TI com a palanca",
  "723": "Joris Raymaekers",
  "724": "té una àmplia experiència en la creació d'aplicacions web rellevants i fàcils d'utilitzar i li agrada ajudar els emprenedors a superar els seus objectius.",
  "725": " Posa't en contacte i reserva una consulta gratuïta en línia immediatament per explorar com ens podem reforçar mútuament.",
  "726": "Exemples d'èxits amb ",
  "727": "clients satisfets...",
  "728": "Descripció de les funcions del lloc web",
  "729": "Més informació sobre BEM",
  "730": "(BEM)",
  "731": "Pretty Good Privacy (PGP)",
  "732": "o els serveis de TOR Onion Router que trobaràs al final d'aquesta pàgina.",
  "733": "Descubre què sé sobre les comunicacions xifrades...",
  "734": " és una biblioteca d'animació de flocs de neu de front-end de codi obert que es pot integrar fàcilment en un lloc web existent. Amb unes senzilles línies de JavaScript, obtindràs una animació HTML5 Canvas amb flocs de neu i moltes opcions, inclosos botons personalitzables que permeten al visitant del lloc web activar o desactivar l'animació. Consulta la descripció detallada i la documentació a la ",
  "735": " o mira el codi al ",
  "736": "Snowfall-js-plugin",
  "737": "pàgina NPM",
  "738": "Github-repo."
}
